import { PRODUCTS_BETTING_RULES } from 'configs/products';
import { NEW_TRACK_PAGE } from '../../configs/main';

export default class SmartLynBet {
    date = null;
    product = null;
    trackCode = null;
    selections = null;
    raceNumbers = null;
    numberOfRaces = 0;
    locked = {};
    multitrack = null;

    static unserialize(date, product, trackCode, races, betSlip, multitrack = null, locked = {}) {
        const smartLynBet = new SmartLynBet();

        const selections = betSlip.getAllRacesSelections(date, trackCode, product.id);

        const multitrackLegsKey = NEW_TRACK_PAGE ? 'legsInfo' : 'legs';

        smartLynBet.date = date;
        smartLynBet.product = product;
        smartLynBet.trackCode = trackCode;
        smartLynBet.selections = selections;
        smartLynBet.raceNumbers = !multitrack
            ? races.map(race => race.raceNumber)
            : multitrack[multitrackLegsKey].map(leg => leg.legNr);
        smartLynBet.numberOfRaces =
            PRODUCTS_BETTING_RULES[product.id] && PRODUCTS_BETTING_RULES[product.id].races
                ? PRODUCTS_BETTING_RULES[product.id].races
                : 0;
        smartLynBet.locked = locked;

        if (multitrack) {
            smartLynBet.multitrack = multitrack;
        }

        return smartLynBet;
    }
}
