import React from 'react';
import styled from 'styled-components';
import getTexts from 'utils/localization';
import DownloadSection from './DownloadSection';
import usePdfDownload from '../usePdfDownload';
import useATGPdfDownload from '../useATGPdfDownload';
import { downloadFileByLink } from 'utils/DOM';
import { trackEvent, trackingIDs } from 'utils/tracking';

const t = getTexts();

const PdfWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 20px;
`;

const PdfDownload = () => {
    const { fileAvailable: tipsPDFAvailable, downloadPdf: downloadTipsPDF } = usePdfDownload({
        type: 'tips',
    });

    const { pdfAvailable: ATGPageWithPDFAvailable, PDFLink: ATGLink } = useATGPdfDownload();

    const handleTipsPdfClick = () => {
        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: t.PdfDownload.tips,
        });

        downloadTipsPDF();
    };

    const handleTrackProgramPdfClick = () => {
        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: t.PdfDownload.courseProgram,
        });

        downloadFileByLink(ATGLink, 'baneprogram.pdf', 'application/pdf');
    };

    return (
        <PdfWrapper>
            {tipsPDFAvailable && (
                <DownloadSection
                    onClick={handleTipsPdfClick}
                    title={t.PdfDownload.tips}
                    description={t.PdfDownload.tipsDesc}
                />
            )}
            {ATGPageWithPDFAvailable && (
                <DownloadSection
                    onClick={handleTrackProgramPdfClick}
                    title={t.PdfDownload.courseProgram}
                    description={t.PdfDownload.courseProgramDesc}
                />
            )}
        </PdfWrapper>
    );
};

export default PdfDownload;
