import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import BetPick from 'features/BetSlip2/components/BetPick';

import { CloseButton, Popup, SuccessIcon } from './styled';
import PopupWrapper from 'ui/PopupWrapper';
import { Fillable, FullScreen } from 'ui/FullScreen';
import PickHeader from './PickHeader';
import Hr from 'ui/Hr';
import Text from 'ui/Text';
import Headline from 'ui/Headline';
import Wrapper from 'ui/Wrapper';
import RaceSummary from './RaceSummary';
import Icon from 'ui/Icon';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';
import { DERBY_LYN_GAMES, PRODUCT_IDS } from 'configs/products';

import { generateEventPath, generatePopupLink, showPopup } from 'utils/navigation';
import { byPlatform, forMobile, isMobile } from 'utils/platforms';
import getTexts from 'utils/localization';

import useSameVPBetPicks from 'features/BetSlip2/hooks/useSameVPBetPicks';
import { clearAllSelections, setPrevAmount } from 'features/BetSlip2/state/actions';
import useBetslipPropsCopy from 'features/BetSlip2/hooks/useBetslipPropsCopy';

import { history } from 'utils/navigation';
import { useBetslipStep } from 'features/BetSlip2/platforms/Desktop';

const t = getTexts();

const Receipt = props => {
    const { betslipPropsCopy } = useBetslipPropsCopy();

    const { isVProduct, product, raceDay, combinationPrice, raceIndex } = betslipPropsCopy;

    const { placeLynBetHandler, hideModal } = props;

    const dispatch = useDispatch();

    const addSameVPBetPicks = useSameVPBetPicks();

    const { setBetslipStep } = useBetslipStep();

    const placeSameVPBetHandler = () => {
        dispatch(clearAllSelections());

        addSameVPBetPicks({ betSlipCopy: betslipPropsCopy.betSlip });

        dispatch(setPrevAmount(parseInt(combinationPrice, 10)));

        isMobile && hideModal('RECEIPT');

        history.push(
            generateEventPath({
                date: raceDay.raceDayDate,
                track: raceDay.trackName,
                productId: product.id === PRODUCT_IDS.V ? PRODUCT_IDS.P : PRODUCT_IDS.V,
                race: raceIndex + 1,
            })
        );

        isMobile && showPopup('BETSLIP_MOBILE');

        setBetslipStep('BETSLIP');
    };

    const placeLynBet = () => {
        placeLynBetHandler(raceDay.raceDayDate, raceDay.track.code, product);
    };

    const isAvailableForDerbyLyn = DERBY_LYN_GAMES.some(productId => productId === product.id);

    const renderBetButton = () => {
        if (product.id === PRODUCT_IDS.V || product.id === PRODUCT_IDS.P) {
            const btnLabel = product.id === PRODUCT_IDS.V ? 'SPIL PLADS' : 'SPIL VINDER';
            const textLabel = product.id === PRODUCT_IDS.V ? 'SPIL SOM PLADS' : 'SPIL SOM VINDER';

            return (
                <Wrapper padding="0" align="center">
                    <Headline align="center" black size="x1">
                        {textLabel}
                    </Headline>

                    <Button
                        margin="0px auto 10px"
                        size="x2"
                        secondary
                        width="240px"
                        onClick={placeSameVPBetHandler}
                        rounded="25px"
                        style={{ display: 'block' }}
                    >
                        {btnLabel}
                    </Button>
                </Wrapper>
            );
        } else if (isAvailableForDerbyLyn) {
            return (
                <Wrapper padding="0" align="center">
                    <Headline align="center" black size="x1">
                        Prøv som Lyn
                    </Headline>

                    <Button
                        margin="0px auto 10px"
                        size="x2"
                        secondary
                        width="240px"
                        onClick={placeLynBet}
                        rounded="25px"
                        style={{ display: 'block' }}
                    >
                        Spil Lyn
                    </Button>
                </Wrapper>
            );
        }

        return null;
    };

    const renderSecondPopup = () => {
        return (
            (isVProduct || product.id === PRODUCT_IDS.P || product.id === PRODUCT_IDS.V) &&
            product.id !== PRODUCT_IDS.DD &&
            product.id !== PRODUCT_IDS.LD
        );
    };

    const styles = props.styles || {};

    return (
        <PopupWrapper
            style={{
                padding: 0,
                ...styles.PopupWrapper,
                top: byPlatform(0, '50px'),
            }}
        >
            <FullScreen style={styles.FullScreen}>
                <Fillable>
                    <Popup
                        recipeMode={props.recipeMode}
                        style={{
                            paddingTop: '5px',
                            ...styles.Popup,
                            marginBottom: '10px',
                        }}
                        data-test-id="receipt-main"
                    >
                        <SuccessIcon
                            style={byPlatform(
                                {},
                                {
                                    position: 'relative',
                                },
                                {
                                    position: 'relative',
                                }
                            )}
                        />
                        {forMobile(
                            <CloseButton
                                onClick={() => hideModal('RECEIPT')}
                                style={{
                                    top: '-15px',
                                }}
                            >
                                <Icon
                                    iclose
                                    color="white"
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                    }}
                                />
                            </CloseButton>
                        )}
                        <h1
                            style={{
                                fontSize: byPlatform('30px', '20px'),
                                color: byPlatform(COLORS.grey, 'inherit'),
                                marginTop: '0px',
                                marginBottom: '0px',
                            }}
                        >
                            {t.receipt.message}
                        </h1>
                        <RaceSummary {...props} {...betslipPropsCopy} />
                        <Hr
                            style={{
                                marginBottom: '0px',
                                marginTop: '10px',
                            }}
                        />

                        <>
                            <PickHeader product={product} isVProduct={isVProduct} />
                            <BetPick {...betslipPropsCopy} />
                        </>

                        <Hr style={{ marginTop: '15px' }} />

                        <Text size="x1" className="text-center">
                            {t.receipt.myGamesText}{' '}
                            <NavLink
                                to={byPlatform(
                                    '/mine-spil',
                                    generatePopupLink('/konto/activebets', 'USER_AREA_DESKTOP')
                                )}
                            >
                                {t.receipt.myGamesLink}
                            </NavLink>
                        </Text>
                    </Popup>
                    {renderSecondPopup() ? (
                        <Popup
                            recipeMode={true}
                            style={{
                                paddingTop: '5px',
                                ...styles.Popup,
                            }}
                            data-test-id="receipt-second"
                        >
                            {renderBetButton()}
                        </Popup>
                    ) : null}
                </Fillable>
            </FullScreen>
        </PopupWrapper>
    );
};

export default Receipt;
