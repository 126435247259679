import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import { numberWithCommasAndDots } from 'utils/formatters';
import { Groups, Paths } from 'configs/user-area';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'themes';
import moment from 'moment';
import getTexts from 'utils/localization';
import { hidePopup, getRouterState } from 'utils/navigation';
import { NotificationIndicator } from '../styled';
const t = getTexts();

const StyledNav = styled.nav`
    display: block;
    padding: 0px;
    margin: 0px;
    & > ul {
        list-style: none;
        padding: 20px 0px 10px;
        margin: 0px;
        & > li {
            padding: 7px 0px;
            margin: 0px;
            list-style: none;
            & > a {
                text-decoration: none;
                font-size: 14px;
                color: ${COLORS.primaryText};
            }
            & > a.active {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    width: 12px;
                    height: 24px;
                    background: ${COLORS.secondary};
                }
            }
            & img {
                filter: brightness(0%);
            }
        }
    }
`;

class Menu extends React.Component {
    componentDidUpdate() {
        if (!this.props.userData && this.props.authFinished) {
            hidePopup('USER_AREA_DESKTOP');
        }
    }

    renderNotification = path => {
        if (path === 'freeBet') {
            return <NotificationIndicator className="ml-5">1</NotificationIndicator>;
        }
    };

    getGroupMenuView = (items, index) => (
        <ul key={index}>
            {items.map((item, index) => {
                return this.getMenuItemView(Paths[item], index, item);
            })}
        </ul>
    );

    getMenuItemView = (path, index, menuItemKey) => {
        return path.url !== 'logout' ? (
            <li key={index}>
                <NavLink
                    to={{ pathname: '/' + path.url, state: getRouterState() }}
                    replace
                    activeClassName="active"
                >
                    <img
                        src={path.icon}
                        alt={path.label}
                        height="24px"
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />{' '}
                    <div className="inline-flex">
                        {path.label}
                        {path.notification && this.renderNotification(menuItemKey)}
                    </div>
                </NavLink>
            </li>
        ) : (
            <li key={index}>
                <Text
                    padding="0px"
                    style={{ cursor: 'pointer' }}
                    onClick={this.props.logoutHandler}
                >
                    <img
                        src={path.icon}
                        alt={path.label}
                        height="24px"
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />{' '}
                    {path.label}
                </Text>
            </li>
        );
    };

    render() {
        const { userData } = this.props;

        if (!userData) {
            return null;
        }

        return (
            <Wrapper padding="20px 20px 20px 40px">
                <div>
                    <Text size={16} bold black padding="0px">
                        {userData.userName}
                    </Text>

                    <Text padding="0px">
                        {numberWithCommasAndDots(userData.balance)} {t.currency}
                    </Text>
                </div>

                <StyledNav>{Groups.map(this.getGroupMenuView)}</StyledNav>

                <Wrapper padding="20px 0px">
                    <Text size="x05" padding="0px" lightGrey>
                        {t.userArea.bottom.userId}: {userData.id}
                    </Text>
                    <Text size="x05" padding="0px" lightGrey>
                        {t.userArea.bottom.lastlogin}:{' '}
                        {moment(userData.lastLoginUTC).format('MM-DD-YYYY, HH:mm:ss')}
                    </Text>
                </Wrapper>
            </Wrapper>
        );
    }
}

export default Menu;
