import AISREST from './AISREST';
import Unserializer from './Unserializer';
import axios from 'axios';
import {
    findMultitrackCoupleBy,
    getMultitrackId,
} from 'common/selectors/multipleTrackSetupsSelector';
import { getDefaultRace, getRace, getRaceByIndex } from 'common/selectors/racesSelector';
import { getDefaultRaceDay, getRaceDayForSelectedProduct } from 'common/selectors/raceDaySelector';
import productSelector from 'common/selectors/productSelector';
import { get } from 'lodash';
import QuickPickDO from 'common/DataObjects/QuickPick';
import { castErrorToString } from 'utils/error.ts';

export const SET_AIS_DATA_PROVIDER_INITIALIZING = 'SET_AIS_DATA_PROVIDER_INITIALIZING';
export const SET_RACE_DAY_SELECTED = 'SET_RACE_DAY_SELECTED';

export const FEATCH_RACE_DAY_PENDING = 'FEATCH_RACE_DAY_PENDING';
export const FEATCH_RACE_DAY_SUCCESS = 'FEATCH_RACE_DAY_SUCCESS';
export const FEATCH_RACE_DAY_ERROR = 'FEATCH_RACE_DAY_ERROR';

export const FEATCH_RACING_CARD_PENDING = 'FEATCH_RACING_CARD_PENDING';
export const FEATCH_RACING_CARD_SUCCESS = 'FEATCH_RACING_CARD_SUCCESS';
export const FEATCH_RACING_CARD_ERROR = 'FEATCH_RACING_CARD_ERROR';

export const FETCH_COUPLE_RACING_CARD_PENDING = 'FETCH_COUPLE_RACING_CARD_PENDING';
export const FETCH_COUPLE_RACING_CARD_SUCCESS = 'FETCH_COUPLE_RACING_CARD_SUCCESS';
export const FETCH_COUPLE_RACING_CARD_ERROR = 'FETCH_COUPLE_RACING_CARD_ERROR';

export const FETCH_RACING_CARD_WITH_POOL_PENDING = 'FETCH_RACING_CARD_WITH_POOL_PENDING';
export const FETCH_RACING_CARD_WITH_POOL_SUCCESS = 'FETCH_RACING_CARD_WITH_POOL_SUCCESS';

export const RESET_RACING_CARD_WITH_POOL_DATA = 'RESET_RACING_CARD_WITH_POOL_DATA';
export const FETCH_RACING_CARD_WITH_POOL_ERROR = 'FETCH_RACING_CARD_WITH_POOL_ERROR';

export const FETCH_POOL_LIST_PENDING = 'FETCH_POOL_LIST_PENDING';
export const FETCH_POOL_LIST_SUCCESS = 'FETCH_POOL_LIST_SUCCESS';
export const FETCH_POOL_LIST_ERROR = 'FETCH_POOL_LIST_ERROR';

export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const SELECT_PRODUCT_SHORTCUT = 'SELECT_PRODUCT_SHORTCUT';
export const SELECT_RACE_DAY = 'SELECT_RACE_DAY';
export const SET_DEFAULT_RACE_DAY = 'SET_DEFAULT_RACE_DAY';
export const SELECT_RACE = 'SELECT_RACE';
export const KEEP_RACE_NUMBER = 'KEEP_RACE_NUMBER';
export const RESET_RACE = 'RESET_RACE';

export const SERVER_TIME = 'SERVER_TIME';

export const SET_INPUT_ERROR_REASON = 'SET_INPUT_ERROR_REASON';

export const SET_EVENT = 'SET_EVENT';

export const SET_EDIT_SELECTIONS_INITIALIZING = 'SET_EDIT_SELECTIONS_INITIALIZING';

/**
 * @param  {boolean} status
 * @return {{payload: boolean, type: string}}
 */
const setInitializing = status => {
    return {
        type: SET_AIS_DATA_PROVIDER_INITIALIZING,
        payload: status,
    };
};

const setEditSelectionsInitializing = status => {
    return {
        type: SET_EDIT_SELECTIONS_INITIALIZING,
        payload: status,
    };
};

const setProduct = (product, meta = {}) => {
    return {
        type: SELECT_PRODUCT,
        payload: product,
        meta,
    };
};

const resetProduct = () => {
    return dispatch => {
        dispatch({
            type: RESET_PRODUCT,
            payload: true,
        });
    };
};

const resetRaceDay = () => {
    return (dispatch, getState) => {
        dispatch(setRaceDay(getDefaultRaceDay(getState())));
        dispatch(setDefaultRaceDay(false));
    };
};

//@see https://ecosys.atlassian.net/browse/DER-1003
const setDefaultRaceDay = (status = true) => {
    return { type: SET_DEFAULT_RACE_DAY, payload: status };
};

const setProductShortcut = product => {
    return (dispatch, getState) => {
        const previousRaceDay = getRaceDayForSelectedProduct(getState());
        const previousProduct = productSelector(getState());
        const previousRace = getRace(getState());

        dispatch({
            type: SELECT_PRODUCT_SHORTCUT,
            payload: product,
        });

        const currentRaceDay = getRaceDayForSelectedProduct(getState());

        if (!currentRaceDay.isSame(previousRaceDay)) {
            dispatch(setRaceDay(currentRaceDay));
        }
        // Fixes the issue when a user selects the product on some race day having ongoing races
        // but it's the same as currently selected product and race day.
        // In this case it resets the race to the first one.
        // and in the UI the BetslipNotifier won't be shown in this case.
        //@see https://ecosys.atlassian.net/browse/DER-1438
        if (currentRaceDay.isSame(previousRaceDay) && previousProduct.id === product.id) {
            dispatch(
                setRace(
                    previousRace ? previousRace.raceNumber : 1,
                    previousRace ? previousRace.index : 0
                )
            );
        }
    };
};

const setRaceDay = (raceDay, meta = {}) => {
    return {
        type: SELECT_RACE_DAY,
        payload: raceDay,
        meta,
    };
};

const setRace = (raceNumber, raceIndex) => {
    return (dispatch, getState) => {
        if (raceNumber === false) {
            raceNumber = getRaceByIndex(getState(), raceIndex).raceNumber;
        }
        dispatch({ type: SELECT_RACE, payload: { raceNumber, raceIndex } });
    };
};

const keepRaceIndexInState = raceIndex => {
    return { type: KEEP_RACE_NUMBER, payload: raceIndex };
};

const resetRace = () => {
    return (dispatch, getState) => {
        const firstRace = getDefaultRace(getState());
        dispatch({
            type: RESET_RACE,
            payload: {
                raceNumber: firstRace ? firstRace.raceNumber : 1,
                raceIndex: firstRace ? firstRace.index : 0,
            },
        });
    };
};

const fetchRaceDayCalendar = withoutPending => {
    return dispatch => {
        if (!withoutPending)
            dispatch({
                type: FEATCH_RACE_DAY_PENDING,
                payload: true,
            });

        return AISREST.fetchRaceDayCalendar().then(res => {
            if (res.success) {
                const raceCalendarData = new Unserializer(res.data).getRacesDay();

                dispatch({
                    type: FEATCH_RACE_DAY_SUCCESS,
                    payload: raceCalendarData,
                });

                return raceCalendarData;
            } else {
                dispatch({
                    type: FEATCH_RACE_DAY_ERROR,
                    payload: res.errorMessage,
                });

                dispatch({
                    type: SELECT_RACE_DAY,
                    payload: null,
                });
            }
        });
    };
};

const fetchRacingCardWithPoolWithoutDispatch = ({
    date,
    trackId,
    productId,
    isMultitrack,
    statePart,
}) => {
    return Promise.all([
        AISREST.fetchRacingCard(productId, date, trackId, {
            cancelRequestsResolver: cancelPrevRacingCardRequests,
        }),
        AISREST.fetchTrackPool(date, trackId),
        AISREST.fetchTrackBetInfo(date, trackId),
        AISREST.fetchMergedPools(date, trackId),
    ])
        .then(([racingCardResponse, trackPoolResponse, betInfoResponse, mergedPoolsResponse]) => {
            let response;
            if (
                racingCardResponse.success &&
                trackPoolResponse.success &&
                betInfoResponse.success &&
                mergedPoolsResponse.success
            ) {
                const trackPool = trackPoolResponse.data;
                const racingCard = new Unserializer(racingCardResponse.data).getRacingCard(
                    trackPool,
                    isMultitrack
                );

                if (isMultitrack) {
                    const coupleTrack = findMultitrackCoupleBy(statePart, date, trackId, productId);

                    if (coupleTrack) {
                        response = Promise.all([
                            AISREST.fetchTrackBetInfo(date, coupleTrack.id),
                        ]).then(betInfo => {
                            return new Promise(resolve => {
                                resolve([
                                    racingCard,
                                    trackPool,
                                    get(betInfo, '[0].data', {}),
                                    coupleTrack,
                                ]);
                            });
                        });
                    }
                } else {
                    response = new Promise(resolve => {
                        resolve([
                            racingCard,
                            trackPool,
                            betInfoResponse.data,
                            // mergedPoolsResponse.data.mergedPools,
                        ]);
                    });
                }
            } else {
                response = new Promise(resolve => {
                    resolve({
                        errors: [
                            racingCardResponse.errorMessage,
                            trackPoolResponse.errorMessage,
                            betInfoResponse.errorMessage,
                            mergedPoolsResponse.errorMessage,
                        ],
                    });
                });
            }

            return response;
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                return;
            }

            console.error(e);
        });
};

const resolveSuccess = () => Promise.resolve({ success: true, data: null });

const fetchRacingCardWithPool = (
    date,
    trackId,
    productId,
    withoutPending = false,
    { skipBetInfo, skipMergedPools } = {}
) => {
    let racingCard, trackPool;
    return (dispatch, getState) => {
        !withoutPending && dispatch({ type: FETCH_RACING_CARD_WITH_POOL_PENDING });
        // for racing card in case of multitrack another track id should be sent (usually 40)
        const racingCardTrackId = getMultitrackId(getState(), { productId }) || trackId;

        const promises = [
            AISREST.fetchRacingCard(productId, date, racingCardTrackId, {
                cancelRequestsResolver: cancelPrevRacingCardRequests,
            }),
            AISREST.fetchTrackPool(date, trackId),
            !skipBetInfo ? AISREST.fetchTrackBetInfo(date, trackId) : resolveSuccess(),
            !skipMergedPools ? AISREST.fetchMergedPools(date, trackId) : resolveSuccess(),
        ];

        return Promise.all(promises)
            .then(
                ([racingCardResponse, trackPoolResponse, betInfoResponse, mergedPoolsResponse]) => {
                    if (
                        racingCardResponse.success &&
                        trackPoolResponse.success &&
                        betInfoResponse.success &&
                        mergedPoolsResponse.success
                    ) {
                        trackPool = trackPoolResponse.data;
                        racingCard = new Unserializer(racingCardResponse.data).getRacingCard(
                            trackPool
                        );

                        dispatch({
                            type: FETCH_RACING_CARD_WITH_POOL_SUCCESS,
                            payload: [
                                racingCard,
                                trackPool,
                                betInfoResponse.data,
                                mergedPoolsResponse.data?.mergedPools,
                            ],
                        });

                        return [racingCard, trackPool, betInfoResponse.data];
                    } else {
                        dispatch({
                            type: FETCH_RACING_CARD_WITH_POOL_ERROR,
                            payload: [
                                racingCardResponse.errorMessage,
                                trackPoolResponse.errorMessage,
                                betInfoResponse.errorMessage,
                                mergedPoolsResponse.errorMessage,
                            ],
                        });

                        dispatch({
                            type: RESET_RACE,
                            payload: 1,
                        });
                    }
                }
            )
            .catch(e => {
                if (axios.isCancel(e)) {
                    return;
                }

                console.error(e);

                dispatch({
                    type: FETCH_RACING_CARD_WITH_POOL_ERROR,
                    payload: [e.message, e.message, e.message, e.message],
                });
            });
    };
};

const fetchPoolList = () => {
    return dispatch => {
        dispatch({ type: FETCH_POOL_LIST_PENDING });

        return AISREST.fetchAllPoolInformation()
            .then(res => {
                if (!res.success) {
                    throw res.errorMessage;
                }

                const data = res.data.map(item => QuickPickDO.unserialize(item));

                dispatch({ type: FETCH_POOL_LIST_SUCCESS, payload: data });

                return data;
            })
            .catch(e => {
                dispatch({
                    type: FETCH_POOL_LIST_ERROR,
                    payload: castErrorToString(e),
                });

                throw e;
            });
    };
};

const fetchServerTime = () => {
    return dispatch => {
        return AISREST.fetchServerTime().then(res => {
            if (res.time) {
                dispatch({
                    type: SERVER_TIME,
                    payload: res.time,
                });
            }
        });
    };
};

const setInputErrorReason = reason => ({
    type: SET_INPUT_ERROR_REASON,
    payload: reason,
});

/**
 * @param {ActiveRequest} activeRequest
 */
const cancelPrevRacingCardRequests = activeRequest => {
    AISREST.getRacingCardEndpoints().some(url => activeRequest.satisfiesUrl(url)) &&
        activeRequest.source.cancel();
};

export {
    setInitializing,
    setEditSelectionsInitializing,
    fetchRaceDayCalendar,
    setProduct,
    resetProduct,
    resetRaceDay,
    setProductShortcut,
    setRaceDay,
    setDefaultRaceDay,
    setRace,
    keepRaceIndexInState,
    resetRace,
    fetchServerTime,
    fetchRacingCardWithPool,
    fetchRacingCardWithPoolWithoutDispatch,
    fetchPoolList,
    setInputErrorReason,
};
