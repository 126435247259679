import { fillObjectByConstructor } from 'utils/object-helper';
import getTexts from 'utils/localization';
const t = getTexts();

class Profile {
    email = '';
    cprNumber = '';
    firstName = '';
    lastName = '';
    zip = '';
    city = '';
    birthDate = ''; // 23-10-1983
    gender = ''; // M|F
    phoneNr = '';
    phoneGSM = '';
    securityQuestion = null;
    mailList = '0';
    securityQuestionValue = null;
    oddsDisplayValue = null;
    address = '';
    country = '';
    supportName = null;
    registerDate = ''; // 018-08-29
    currency = t.currency;
    valid = true;
    userId = 0;
    extraInfo = {
        lastIp: '',
        lastLogin: 0,
        loginTime: 0,
    };

    /**
     *
     * @param   {Object}  data
     * @returns {Profile}
     */
    static unserialize(data) {
        return fillObjectByConstructor(data, Profile);
    }

    /**
     * Sending the whole object to server ends up with 400 status code
     * @return {Object}  Serialized object
     */
    serialize() {
        const { email, phoneNr } = this;
        return { email, phoneNr };
    }
}

export default Profile;
