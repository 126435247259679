import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import FlexWrapper from 'ui/FlexWrapper';
import getTexts from 'utils/localization';
import { getUITrackName } from 'common/selectors/trackSelector';
import { useSelector } from 'react-redux';
const t = getTexts();

const RaceInfo = props => {
    const trackName = useSelector(getUITrackName);

    return (
        <Wrapper padding="0px 45px" maxWidth={'300px'} margin="0 auto">
            <FlexWrapper padding="0px" direction="row" align="space-evenly">
                <Text size="14" align="center" bold>
                    {trackName}
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {props.race.trotRaceInfo && props.race.trotRaceInfo.startMethod
                        ? props.race.trotRaceInfo.monte
                            ? t.RaceType.monte
                            : t.RaceType.trot
                        : t.RaceType.gallop}
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                {props.race.gallopRaceInfo && props.race.gallopRaceInfo.trackSurface ? (
                    <Text size="14" align="center" bold>
                        {props.race?.gallopRaceInfo?.trackSurface?.code === 'gr'
                            ? 'Græs'
                            : props.race.gallopRaceInfo?.trackSurface?.domesticText ?? 'n/a'}
                    </Text>
                ) : (
                    <Text size="14" align="center" bold>
                        {props.race.trotRaceInfo && props.race.trotRaceInfo.startMethod
                            ? props.race.trotRaceInfo.startMethod.domesticText
                            : 'n/a'}
                    </Text>
                )}
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {props.race.distance}M
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {t.RacingCard.race} {props.race.index + 1}
                </Text>
            </FlexWrapper>
        </Wrapper>
    );
};

export default RaceInfo;
