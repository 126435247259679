import React, { Component } from 'react';

import {
    BetPickBtn,
    ReserveNumber,
} from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';
import { PickButtonsContainerOld } from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';

class BetButton extends Component {
    render() {
        const { isActive, betValue, clickHandler, scratched, reserved, reserveNumber } = this.props;

        return (
            <PickButtonsContainerOld>
                <BetPickBtn
                    {...this.props}
                    active={isActive(betValue)}
                    size="x1"
                    onClick={e => clickHandler(e, betValue)}
                    disallowed={scratched && !isActive(betValue)}
                    scratched={scratched}
                >
                    {betValue}
                    {reserved && <ReserveNumber>{reserveNumber}</ReserveNumber>}
                </BetPickBtn>
            </PickButtonsContainerOld>
        );
    }
}

export default BetButton;
