import React from 'react';
import { useDidMount } from 'rooks';
import { Redirect } from 'react-router-dom';

export const enableEruda = () => {
    import('eruda').then(eruda => eruda.default.init());
};

const Eruda = () => {
    useDidMount(enableEruda);

    return <Redirect to={'/'} />;
};

export default Eruda;
