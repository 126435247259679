import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import UserSessionAdapter from 'common/adapters/UserSession';
import { getTranslatedErrorMessage } from 'utils/betting-service';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { BET_API_MARKS_STRING_LENGTH } from 'configs/products';
import racesSelector, { getLegNumbers } from 'common/selectors/racesSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import { performQuickPickBet as performQuickBet } from 'features/BetSlip/state/actions';
import { getLockedLegs } from 'features/MobileBet/selectors/lockedLegsSelector';
import { getSmartLynBet } from 'common/selectors/smartLynSelector';
import useTrackPage from '../TrackPage/hooks/useTrackPage';
import betSlipSelector from 'common/selectors/betSlipSelector';
import SmartLynBet from 'common/DataObjects/SmartLynBet';
import { NEW_TRACK_PAGE } from 'configs/main';
import useAuth from 'common/hooks/useAuth';
import { clearAllSelections } from 'features/BetSlip2/state/actions';
import { updateUserInfo } from 'common/actions/authActions';

// @TODO: We should get rid of  withQuickPickBetting for Smart Lyn and use usePerformQuickPickBet hook instead
//  + get rid of withSmartLynManager and do some additional hook for smart lyn specifically as it has much more code than regular quick pick bets
// Note: withQuickPickBetting is used for Smart lyn only (for NEW_TRACK_PAGE)
const withQuickPickBetting = WrappedComponent => {
    return props => {
        const { noSmartLyn, isUserLoggedIn, track } = props;
        const [pending, setPending] = useState(false);
        const [amount, setAmount] = useState(props.amount || 50);

        const {
            raceDay,
            product,
            productId,
            trackCode,
            isMultitrack,
            multitrackTrackId,
            races,
            product: trackPageProduct,
        } = useTrackPage();

        // locked legs
        const racesLegacy = useSelector(racesSelector, shallowEqual);
        const isMultitrackLegacy = useSelector(isMultitrackProduct);

        // selectors
        const betSlip = useSelector(betSlipSelector);

        const auth = useAuth();

        const smartLynBet = NEW_TRACK_PAGE
            ? SmartLynBet.unserialize(
                  raceDay.raceDayDate,
                  product,
                  trackCode,
                  races,
                  betSlip,
                  isMultitrack
                      ? raceDay.multipleTrackPoolSetups.find(setup => setup.product === productId)
                      : null
              )
            : useSelector(getSmartLynBet);

        const selectedLegNumbers = Object.entries(smartLynBet.selections)
            .filter(([, selections]) => Object.keys(selections).length !== 0)
            .map(([legNr]) => legNr);

        const legacyRaceNumbers = useSelector(getLegNumbers, shallowEqual);
        const raceNumbers = races.map(r => (isMultitrack ? r.legNr : r.raceNumber));

        const mobileBetData = useSelector(getLockedLegs);
        const lockedLegDefaults = mobileBetData.mobileBetInit
            ? mobileBetData.lockedLegs
            : (NEW_TRACK_PAGE ? raceNumbers : legacyRaceNumbers).filter(
                  lnr => !selectedLegNumbers.includes(lnr)
              );

        const [lockedLegs, setLockedLegs] = useState(lockedLegDefaults);

        const hasUnselectedLegs =
            selectedLegNumbers.length < (NEW_TRACK_PAGE ? races : racesLegacy).length;

        smartLynBet.locked = lockedLegs;

        const toggleLegStatus = legNr =>
            lockedLegs.includes(legNr)
                ? setLockedLegs(lockedLegs.filter(lnr => lnr !== legNr))
                : setLockedLegs([...lockedLegs, legNr]);

        const createSmartLynLegs = () => {
            return smartLynBet.raceNumbers.map((raceNumber, index) => {
                const apiMarksStringLength = BET_API_MARKS_STRING_LENGTH[smartLynBet.product.id];
                let marks = '';

                if (smartLynBet.selections[index]) {
                    for (let i = 0; i < apiMarksStringLength; i++) {
                        marks += '' + (smartLynBet.selections[index][i + 1] ? 1 : 0);
                    }
                } else {
                    for (let i = 0; i < apiMarksStringLength; i++) {
                        marks += '0';
                    }
                }

                return {
                    legNumber: index + 1,
                    marks,
                    open:
                        smartLynBet.selections[index] &&
                        Object.keys(smartLynBet.selections[index])?.length
                            ? !lockedLegs.includes(raceNumber)
                            : true,
                };
            });
        };

        const dispatch = useDispatch();
        const performQuickPickBet = async () => {
            setPending(true);
            // QUICK_PICK_AMOUNT modal uses passed `product` prop, but for the regular bet we need track page product
            const product = props.product ?? trackPageProduct;
            const date = NEW_TRACK_PAGE ? raceDay.raceDayDate : props.date;
            const trackId = NEW_TRACK_PAGE ? raceDay.trackId : props.track.id;
            const programNumber = NEW_TRACK_PAGE ? raceDay.programNumber : props.programNumber;
            const selectedRaceDay = NEW_TRACK_PAGE ? raceDay : props.selectedRaceDay;

            const { smartLynMode } = props;

            const trackCodeLegacy =
                smartLynMode && isMultitrackLegacy ? smartLynBet.multitrack.trackId : trackId;

            const params = {
                raceDay: date.substring(0, 10),
                trackCode: NEW_TRACK_PAGE ? multitrackTrackId : trackCodeLegacy,
                maxTotalBetAmount: amount,
                programNumber:
                    smartLynMode && isMultitrackLegacy
                        ? smartLynBet.multitrack.programNumber
                        : programNumber,
            };

            let postTime = moment(date).format('YYYY-MM-DD HH:mm');

            if (smartLynMode && smartLynBet && !noSmartLyn) {
                params.smartLyn = true;
                params.legs = createSmartLynLegs();
                postTime = selectedRaceDay.firstRacePostTime;
            }

            const bettingData = UserSessionAdapter.getPerformSaleData(
                params,
                auth.user.id,
                null,
                null,
                postTime
            );

            try {
                const res = await dispatch(performQuickBet(bettingData, product));

                if (res.success) {
                    // first argument is skipped because below we call isUserLoggedIn anyway
                    UserSessionAdapter.performUserUpdate(
                        () => {},
                        authData => dispatch(updateUserInfo(authData)),
                        res
                    );

                    props.onBetPerformed(res, { product, raceDay, smartLynMode, track });

                    NEW_TRACK_PAGE && dispatch(clearAllSelections());

                    isUserLoggedIn && isUserLoggedIn();
                } else {
                    setPending(false);

                    const error = getTranslatedErrorMessage(res);
                    NotificationConductor.error(error);
                }
            } catch (error) {
                setPending(false);

                NotificationConductor.error(error.message);
                console.error(error);
            }
        };

        return (
            <WrappedComponent
                {...props}
                performQuickPickBet={performQuickPickBet}
                setQuickPickAmount={setAmount}
                pending={pending}
                quickPickBetPending={pending}
                lockedLegs={lockedLegs}
                toggleLegStatus={toggleLegStatus}
                allLegsLocked={
                    hasUnselectedLegs ? false : selectedLegNumbers.length === lockedLegs.length
                }
                createSmartLynLegs={createSmartLynLegs}
            />
        );
    };
};

export default withQuickPickBetting;
