import React, { useState } from 'react';

import { RaceCardWrapper, TableHeader, TableHeaderCol, JackpotRow } from './styled';

import { AnyRace } from 'features/TrackPage/model/Race';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import { PRODUCT_IDS } from 'configs/products';
import RaceCardHeader from './RaceCardHeader';
import { thousandsDots } from 'utils/formatters';
import { extractJackpot } from 'features/TrackPage/server/pool';
import YouthWarning from '../common/YouthWarning';
import { extractRaceShoeInfo } from 'features/TrackPage/server/trackBetInfo';
import { shouldHideNrCol } from '../utils';
import TrioPickColumnButtons from '../common/TrioPickColumnButtons';
import KombPickColumnButtons from '../common/KombPickColumnButtons';
import HorseRow from './HorseRow';

import getTexts from 'utils/localization';
const t = getTexts();

const { T, TV, K } = PRODUCT_IDS;

interface RaceCardProps {
    race: AnyRace;
}

const nrColText = { [T]: 'Placering', [TV]: 'Tvilling' };

const RaceCard = ({ race }: RaceCardProps) => {
    const { raceDay, isVStrictProduct, productId, product, productPool, trackNames, trackBetInfo } =
        useTrackPage();

    const { reservesMode, toggleReservesMode } = useReservesMode();

    const [statsExpanded, setStatsVisibility] = useState(false);
    const toggleStats = () => setStatsVisibility(!statsExpanded);

    const raceShoeInfo = extractRaceShoeInfo(trackBetInfo, race.raceNumber);

    const jackpot = extractJackpot(productPool);
    const showJackpot = isVStrictProduct && jackpot > 0;

    const hideNrCol = shouldHideNrCol(race, productId);

    const isTrio = productId === T;
    const isKomb = productId === K;
    const productHasSelectColumnFunctionality = isTrio || isKomb;

    if (race.youthRace) {
        return (
            <RaceCardWrapper>
                <YouthWarning race={race} track={{ name: trackNames[0] }} />
            </RaceCardWrapper>
        );
    }

    return (
        <RaceCardWrapper>
            <RaceCardHeader
                raceDay={raceDay}
                race={race}
                reservesMode={reservesMode}
                toggleReservesMode={toggleReservesMode}
                isVStrictProduct={isVStrictProduct}
                statsExpanded={statsExpanded}
                toggleStats={toggleStats}
            />

            {showJackpot && <JackpotRow>Jackpot overført: {thousandsDots(jackpot)} kr.</JackpotRow>}

            <>
                {isTrio && race.saleOpen && <TrioPickColumnButtons />}
                {isKomb && race.saleOpen && <KombPickColumnButtons />}

                {/* Table Header */}
                <TableHeader
                    hideNrCol={hideNrCol}
                    isVStrictProduct={isVStrictProduct}
                    productId={productId}
                    productHasSelectColumnFunctionality={productHasSelectColumnFunctionality}
                >
                    <TableHeaderCol align={'center'}>
                        {nrColText[productId] ?? 'Nr.'}
                    </TableHeaderCol>
                    <TableHeaderCol>Hest/Kusk</TableHeaderCol>
                    {isVStrictProduct && (
                        <TableHeaderCol align={'center'}>{product.name}%</TableHeaderCol>
                    )}
                    <TableHeaderCol align={'center'}>V-Odds</TableHeaderCol>
                    <TableHeaderCol align={'center'}>{t.RacingCard.subHeader.stats}</TableHeaderCol>
                </TableHeader>

                {/* Content */}
                {race.horses.map((horse, index) => (
                    <HorseRow
                        horse={horse}
                        index={index}
                        race={race}
                        raceShoeInfo={raceShoeInfo}
                        statsExpanded={statsExpanded}
                    />
                ))}
            </>
        </RaceCardWrapper>
    );
};

export default RaceCard;
