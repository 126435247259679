import React, { useRef } from 'react';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';

import { history } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';

import { PreloaderButtons, RaceLineNavigationButton } from 'ui/RaceNavigation';
import Text from 'ui/Text';
import { RaceButtonsContainer } from '../styled';
import { NEW_TRACK_PAGE } from 'configs/main';
import { useSelector } from 'react-redux';
import { AnyRace } from 'features/TrackPage/model/Race';
import { TrackPool } from 'features/TrackPage/server/pool';

function isSaleOpenVProduct(race: AnyRace, trackPool: TrackPool) {
    const vinderPool = trackPool.V;
    // track pool might be not fetched yet
    if (!vinderPool) {
        return true;
    }

    const racePool = vinderPool.vpPoolInfo.find(
        racePool => racePool.raceNumber === race?.raceNumber
    );
    // it's better to fallback to true value in case if a race pool isn't found for any reason
    // to not show races as accent/grey because race pool data isn't available
    return racePool ? racePool.saleOpen : true;
}

const RaceButtons = ({
    raceLineItems = [],
    legacyRacesData = [],
    // eslint-disable-next-line unused-imports/no-unused-vars
    legacySetRace = (raceOrderNumber: number, idx: number) => {},
    legacySelectedRace = 0,
}) => {
    const { race: selectedRace, generateURL } = useURLParameters();
    const {
        isVProduct,
        calendarLoading,
        betType,
        races,
        multitrackSetup,
        raceDay,
        poolQueryData: trackPool,
    } = useTrackPage();

    const trackPoolLegacy = useSelector<{ AISDataProvider: { trackPool: TrackPool } }, TrackPool>(
        state => state.AISDataProvider.trackPool
    );

    const raceLineRef = useRef();

    const racesData = NEW_TRACK_PAGE ? races : legacyRacesData;

    const raceNumbers = !NEW_TRACK_PAGE
        ? raceLineItems
        : multitrackSetup
        ? multitrackSetup.bettingRaceNumbers
        : isVProduct
        ? betType.races // youth race numbers are not a part of betType object, so we have to add them manually
        : [...betType.races, ...raceDay.youthRaces].sort((a, b) => a - b);

    const onSelectRace = (raceOrderNumber: number, idx: number) => {
        if (!NEW_TRACK_PAGE) {
            legacySetRace(raceOrderNumber, idx);
        }
        history.push(generateURL({ race: raceOrderNumber }));
    };

    const rowProps = { padding: '0', size: 'x1', weight: '900', color: 'inherit' };

    return calendarLoading ? (
        <PreloaderButtons count={byPlatform(6, 9)} width={'45px'} height={'50px'} />
    ) : (
        <RaceButtonsContainer data-test-id="races" ref={byPlatform(raceLineRef, null)}>
            {raceNumbers.map((raceNumber, idx) => {
                const raceOrderNumber = idx + 1;

                const race = racesData[idx];

                const saleOpen = isVProduct
                    ? isSaleOpenVProduct(race, NEW_TRACK_PAGE ? trackPool : trackPoolLegacy)
                    : race?.saleOpen;

                return (
                    <RaceLineNavigationButton
                        key={idx}
                        onClick={() => onSelectRace(raceOrderNumber, idx)}
                        active={
                            (NEW_TRACK_PAGE ? Number(selectedRace) : legacySelectedRace) ===
                            raceOrderNumber
                        }
                        accent={!saleOpen}
                    >
                        <Text {...rowProps}>{isVProduct ? 'Afd.' : 'Løb'}</Text>
                        <Text {...rowProps}>{isVProduct ? raceOrderNumber : raceNumber}</Text>
                    </RaceLineNavigationButton>
                );
            })}
        </RaceButtonsContainer>
    );
};

export default RaceButtons;
