import React from 'react';
import Wrapper, { FilledWrapper } from 'ui/Wrapper';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import {
    resetProduct,
    resetRace,
    setDefaultRaceDay,
    setRaceDay,
} from 'features/AISDataProvider/actions';
import { setDate } from 'ui/DatePicker/actions';
import Spinner from 'ui/Spinner';
import { sidebar } from 'configs/layout';
import { TopBar } from '@it25syv/25syv-ui';
import { BACKGROUNDS } from 'themes/index';
import { trackEvent, trackingIDs } from 'utils/tracking';
import { showPopup } from 'utils/navigation';
import { isFreeBetAvailable } from '../../../common/selectors/auth';
import { FREE_BET_ENABLED } from '../../../configs/main';

const StyledFilledWrapper = styled(FilledWrapper)`
    background: ${BACKGROUNDS.navigation};
    position: fixed;
    z-index: 99;
    height: 64px;
    top: 59px;
    transition: all 0.4s linear;
    width: calc(
        100% -
            ${props =>
                props.isLeftSideBarOpen ? sidebar.width + 'px' : sidebar.widthClosed + 'px'}
    );

    & button {
        font-family: 'Roboto Condensed';
        font-weight: 900;
        font-size: 14px;
    }
`;

class FullWidthTopBar extends React.Component {
    constructor() {
        super();

        this.state = {
            hideLoginBeforeCheck: true,
        };
    }
    openDeposit = () => {
        trackEvent({
            event: trackingIDs.deposit.topBar,
            userId: this.props?.user?.id || 0,
            action: 'payment_topbar',
            category: 'ux',
        });
        showPopup('USER_AREA_DESKTOP', { params: { subpage: 'indbetaling' } });
    };

    openSignUp = () => {
        this.props.showSignupBar();
    };

    openUserArea = () => {
        trackEvent({
            event: trackingIDs.deposit.accountArea,
            action: 'payment_accountarea',
            category: 'ux',
        });
        showPopup('USER_AREA_DESKTOP', { params: { subpage: 'indbetaling' } });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.loginPending && prevProps.loginPending !== this.props.loginPending) {
            this.setState({ hideLoginBeforeCheck: false });
        }
    }

    render() {
        const { freeBetAvailable } = this.props;

        return (
            <StyledFilledWrapper
                green
                margin={`0 0 0 ${
                    this.props.isLeftSideBarOpen ? sidebar.width : sidebar.widthClosed
                }px`}
                padding={`0`}
                isLeftSideBarOpen={this.props.isLeftSideBarOpen}
            >
                {this.state.hideLoginBeforeCheck && this.props.loginPending ? (
                    <Wrapper padding="0px 20px" align="right">
                        <Spinner />
                    </Wrapper>
                ) : (
                    <TopBar
                        background={BACKGROUNDS.navigation}
                        onBurgerMenuClickHandler={this.props.onBurgerClickHandler}
                        sideNavOpen={this.props.isLeftSideBarOpen}
                        sideNavHandler={this.props.onBurgerClickHandler}
                        userEmail={this.props.authorized}
                        username={this.props.userName}
                        balance={this.props.balance}
                        secondColor={BACKGROUNDS.navigationActiveElement}
                        bankClickHandler={this.openDeposit}
                        showUserArea={this.openUserArea}
                        signUpHandler={this.openSignUp}
                        showLoginModal={() => showPopup('LOGIN')}
                        hasNotification={freeBetAvailable && FREE_BET_ENABLED}
                    />
                )}
            </StyledFilledWrapper>
        );
    }
}

const mapStateToProps = state => {
    const loginPending = state.auth.pending;
    const authorized = state.auth.login.success || state.auth.user;

    return {
        user: state.auth.user,
        loginPending,
        authorized,
        userName: authorized ? state.auth.user.userName : null,
        balance: authorized ? state.auth.user.balance : null,
        freeBetAvailable: isFreeBetAvailable(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSignupBar: () => {
            dispatch(showModal('SIGNUP'));
        },
        showUserAreaModal: () => {
            dispatch(showModal('USER_AREA_MENU'));
        },
        resetProduct: () => {
            dispatch(resetProduct());
        },
        setDate: date => dispatch(setDate(date)),
        resetRaceDay: () => {
            dispatch(setRaceDay(null));
        },
        resetRace: () => dispatch(resetRace()),
        setDefaultRaceDay: defaultRaceDay => {
            dispatch(setDefaultRaceDay(defaultRaceDay));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullWidthTopBar);
