import { THEME, DK_THEME } from '../configs/main';
import DK_COLORS from './dk/colors';
import DK_BACKGROUNDS from './dk/backgrounds';
import DK_TYPOGRAPHY from './dk/typography';
import DK_DIMENSIONS from './dk/dimensions';

let COLORS: Colors, BACKGROUNDS: Backgrounds, TYPOGRAPHY, DIMENSIONS;

interface Colors {
    primary: string;
    accent: string;
    secondary: string;
    primaryText: string;
    mutedText: string;
    placeholderText: string;
    accentText: string;
    secondaryText: string;
    red: string;
    blue: string;
    grey: string;
    lightGreen: string;
    lightGrey: string;
    white: string;
    dark: string;
    black: string;
    overlay200: string;
    overlay300: string;
    special: SpecialColors;
    blockShadow: string;
}

interface SpecialColors {
    darkBlue: string;
    purple: string;
    black: string;
    red: string;
    orange: string;
    blue: string;
    green: string;
    calm: string;
}

interface Backgrounds {
    primary: string;
    primaryFlat: string;
    accent: string;
    accentAlt: string;
    secondary: string;
    red: string;
    blue: string;
    grey: string;
    lightGrey: string;
    newLightGrey: string;
    white: string;
    black: string;
    darkgreen: string;
    none: string;
    hugeBlockGradient: string;
    navigation: string;
    navigationActiveElement: string;
    neutral: string;
}

switch (THEME) {
    case DK_THEME:
        COLORS = DK_COLORS;
        BACKGROUNDS = DK_BACKGROUNDS;
        TYPOGRAPHY = DK_TYPOGRAPHY;
        DIMENSIONS = DK_DIMENSIONS;
        break;
    default:
        COLORS = DK_COLORS;
        BACKGROUNDS = DK_BACKGROUNDS;
        TYPOGRAPHY = DK_TYPOGRAPHY;
        DIMENSIONS = DK_DIMENSIONS;
}

export { COLORS, BACKGROUNDS, TYPOGRAPHY, DIMENSIONS };
