import React, { Fragment } from 'react';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import { thousandsDots } from 'utils/formatters';
import { byPlatform } from 'utils/platforms';

const Container = styled.div`
    overflow-wrap: break-word;
`;

const Line = styled.div`
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
`;

const PoolItem = styled.div`
    padding-right: 5px;
`;

const getProductPools = poolOverview => {
    return poolOverview && poolOverview.poolByProduct
        ? Object.keys(poolOverview.poolByProduct)
        : [];
};

const getMergedPools = poolOverview => {
    return poolOverview.mergedPools || [];
};

const isProductPoolEmpty = (poolOverview, raceNr) => {
    return getProductPools(poolOverview).every(productId => {
        const amount = poolOverview.poolByProduct[productId].getAmount(raceNr);
        return amount === 0 || isNil(amount);
    });
};

const isMergedPoolEmpty = (poolOverview, raceNr) => {
    return getMergedPools(poolOverview).every(({ turnovers }) => {
        const amount = turnovers[raceNr];
        return amount === 0 || isNil(amount);
    });
};

export const isPoolEmpty = (poolOverview, raceNr) => {
    return isProductPoolEmpty(poolOverview, raceNr) && isMergedPoolEmpty(poolOverview, raceNr);
};

const PoolOverview = ({ poolOverview, race, Label = Fragment, Value = Fragment }) => {
    const mergedPools = getMergedPools(poolOverview);
    const productPools = getProductPools(poolOverview);

    const mergedPoolEmpty = isMergedPoolEmpty(poolOverview, race.raceNumber);
    const productPoolEmpty = isProductPoolEmpty(poolOverview, race.raceNumber);

    return (
        <Container>
            {mergedPoolEmpty ? null : (
                <Line>
                    {mergedPools.map(({ products, turnovers }) => {
                        const amount = turnovers[race.raceNumber];
                        return amount ? (
                            <PoolItem key={products.join('+')}>
                                <Label
                                    style={{
                                        paddingRight: '3px',
                                        fontWeight: 900,
                                    }}
                                >
                                    Samlet pulje
                                </Label>
                                <Label>
                                    ({products.join(' + ')}){byPlatform(':', ' - ')}
                                </Label>
                                <Value>{thousandsDots(amount)} kr.</Value>
                            </PoolItem>
                        ) : null;
                    })}
                </Line>
            )}

            {productPoolEmpty ? null : (
                <Line>
                    {productPools.map(productId => {
                        const amount = poolOverview.poolByProduct[productId].getAmount(
                            race.raceNumber
                        );
                        return amount ? (
                            <PoolItem key={productId}>
                                <Label doubleHeight={true}>
                                    {productId}
                                    {byPlatform(':', ' - ')}
                                </Label>
                                <Value>
                                    {thousandsDots(amount)}
                                    &nbsp;kr.
                                </Value>
                            </PoolItem>
                        ) : null;
                    })}
                </Line>
            )}
        </Container>
    );
};

export default PoolOverview;
