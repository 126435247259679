import React from 'react';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useRacePicks, { BetPickModifier } from 'features/TrackPage/hooks/useRacePicks';
import { AnyRace, Horse } from 'features/TrackPage/model/Race';

import { PRODUCT_IDS } from 'configs/products';
import { BetPickBtn, PickButtonsContainer, ReserveNumber } from './PickButtons.styled';

import useToggleHorseSelection from 'features/TrackPage/hooks/useToggleHorseSelection';
import { trackEvent, trackingIDs } from 'utils/tracking';

interface PickButtonsProps {
    horse: Horse;
    race: AnyRace;
}

const PickButtons = ({ horse, race }: PickButtonsProps) => {
    const { productId } = useTrackPage();

    const { isPicked, picked, reserved, reserveNumber } = useRacePicks({
        startNr: horse.startNr,
        race,
    });

    const { toggleSelection } = useToggleHorseSelection({ horse, race });

    const scratched = horse.vpPool.scratched;

    const handleClick = (e: Event, betPickModifier?: BetPickModifier) => {
        e.stopPropagation();

        if (productId === PRODUCT_IDS.TV && betPickModifier === 'U') {
            trackEvent({
                event: trackingIDs.racingCard.twinUBtn,
                action: 'u_twin',
                category: 'ux',
            });
        }

        toggleSelection(betPickModifier);
    };

    if (race.poolClosed) {
        return <>{horse.startNr}.</>;
    }

    if (productId === PRODUCT_IDS.TV) {
        return (
            <PickButtonsContainer center>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e)}
                    active={picked}
                    scratched={scratched}
                    disallowed={scratched && !picked}
                >
                    {horse.startNr}
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 'U')}
                    active={isPicked('U')}
                    scratched={scratched}
                    disallowed={scratched && !isPicked('U')}
                >
                    U
                </BetPickBtn>
            </PickButtonsContainer>
        );
    }

    if (productId === PRODUCT_IDS.K) {
        return (
            <PickButtonsContainer center>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 1)}
                    active={isPicked(1)}
                    scratched={scratched}
                    disallowed={scratched && !picked}
                >
                    1
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 2)}
                    active={isPicked(2)}
                    scratched={scratched}
                    disallowed={scratched && !isPicked(2)}
                >
                    2
                </BetPickBtn>
            </PickButtonsContainer>
        );
    }

    if (productId === PRODUCT_IDS.T) {
        return (
            <PickButtonsContainer>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 1)}
                    active={isPicked(1)}
                    scratched={scratched}
                    disallowed={scratched && !isPicked(1)}
                >
                    1
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 2)}
                    active={isPicked(2)}
                    scratched={scratched}
                    disallowed={scratched && !isPicked(2)}
                >
                    2
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 3)}
                    active={isPicked(3)}
                    disallowed={scratched && !isPicked(3)}
                >
                    3
                </BetPickBtn>
            </PickButtonsContainer>
        );
    }

    return (
        <BetPickBtn
            onClick={(e: Event) => handleClick(e)}
            active={picked}
            disallowed={scratched && !picked}
            reserved={reserved}
        >
            {horse.startNr}
            {reserved && <ReserveNumber>{reserveNumber}</ReserveNumber>}
        </BetPickBtn>
    );
};

export default PickButtons;
