import React from 'react';
import { SuccessModal } from '@it25syv/25syv-ui';

import { Overlay } from 'ui/Popup';
import Modal, { useModalAnimation } from 'ui/ModalContent';
import classNames from 'classnames';
import useModals from 'common/hooks/useModals';

export const SuccessProfileUpdateMobile = () => {
    const { animateHide } = useModals();

    return (
        <Overlay>
            <SuccessModal
                closeModal={() => animateHide('PROFILE_SAVE_SUCCESS')}
                title={`Oplysninger opdateret`}
                text={`Dine kontooplysninger er nu opdateret og gemt.`}
            />
        </Overlay>
    );
};

const SuccessProfileUpdateDesktop = () => {
    const { animateHide, onAnimationEnd, hiding } = useModalAnimation({
        id: 'PROFILE_SAVE_SUCCESS',
    });

    return (
        <Modal
            className={classNames('modal-fade', { 'modal-fade-out': hiding })}
            onAnimationEnd={onAnimationEnd}
            priority={3}
        >
            <Overlay>
                <SuccessModal
                    closeModal={animateHide}
                    title={`Oplysninger opdateret`}
                    text={`Dine kontooplysninger er nu opdateret og gemt.`}
                />
            </Overlay>
        </Modal>
    );
};

export default SuccessProfileUpdateDesktop;
