import React from 'react';
import styled from 'styled-components';
import { Profile as ProfileForm } from '@it25syv/25syv-ui';
import NotificationConductor from 'common/conductors/NotificationConductor';

import NewTopBar from '../NewTopBar';

import { byPlatform, forMobile } from 'utils/platforms';
import { getUrl, showPopup } from 'utils/navigation';

import useModals from 'common/hooks/useModals';

import { SUPPORT_EMAIL } from 'configs/main';

import getTexts from 'utils/localization';
const t = getTexts();

const ProfileFormContainer = styled.div`
    // --fix-fonts-inconsistency
    font-weight: 600;
    & button {
        font-weight: 900;
    }
`;

const UserProfile = () => {
    const onMobileModalClose = () => {
        window.location.pathname = getUrl('/konto');
    };

    const showError = error => NotificationConductor.error(error.toString());

    const { show: showModal } = useModals();

    const showSuccess = () => {
        byPlatform(
            showModal('PROFILE_SAVE_SUCCESS', 3),
            showPopup('PROFILE_SAVE_SUCCESS', { keepPreviousBackgroundLocation: true })
        );
    };

    const punterSalt = process.env.REACT_APP_PUNTER_SALT;

    return (
        <>
            {forMobile(
                <NewTopBar goBack={onMobileModalClose} close={onMobileModalClose}>
                    {t.userArea.profile.title}
                </NewTopBar>
            )}

            <ProfileFormContainer>
                <ProfileForm
                    supportEmail={SUPPORT_EMAIL}
                    showSuccess={showSuccess}
                    showError={showError}
                    punterSalt={punterSalt}
                />
            </ProfileFormContainer>
        </>
    );
};

export default UserProfile;
