import { COLORS } from '../themes';
import Product from 'common/DataObjects/Product';
import V75Bg from 'images/atg-style-shortcut-backgrounds/V75.png';
import V86Bg from 'images/atg-style-shortcut-backgrounds/V86.png';
import GS75Bg from 'images/atg-style-shortcut-backgrounds/GS75.png';
import V65Bg from 'images/atg-style-shortcut-backgrounds/V65.png';
import V64Bg from 'images/atg-style-shortcut-backgrounds/V64.png';
import V5Bg from 'images/atg-style-shortcut-backgrounds/V5.png';
import V4Bg from 'images/atg-style-shortcut-backgrounds/V4.png';
import VinderBg from 'images/atg-style-shortcut-backgrounds/vinder.png';
import VPBg from 'images/atg-style-shortcut-backgrounds/VP.png';
import PladsBg from 'images/atg-style-shortcut-backgrounds/plads.png';
import TwinBg from 'images/atg-style-shortcut-backgrounds/tvilling.png';
import TrioBg from 'images/atg-style-shortcut-backgrounds/trio.png';
import DDBg from 'images/atg-style-shortcut-backgrounds/DD.png';
import LDBg from 'images/atg-style-shortcut-backgrounds/LD.png';
import kombBg from 'images/atg-style-shortcut-backgrounds/komb.png';
import bgGS75 from 'images/atg-style/GS75.png';
import bgV45 from 'images/atg-style/V4-5.png';
import bgV64 from 'images/atg-style/V64.png';
import bgV65 from 'images/atg-style/V65.png';
import bgV75 from 'images/atg-style/V75.png';
import bgV86 from 'images/atg-style/V86.png';
import lGS75 from 'images/atg-style/GS75-label.png';
import lV4 from 'images/atg-style/V4-label.png';
import lV5 from 'images/atg-style/V5-label.png';
import lV64 from 'images/atg-style/V64-label.png';
import lV65 from 'images/atg-style/V65-label.png';
import lV75 from 'images/atg-style/V75-label.png';
import lV86 from 'images/atg-style/V86-label.png';
const colors = COLORS.special;

export const PRODUCTS_COLORS: Record<string, string> = {
    V75: colors.darkBlue,
    V86: colors.purple,
    GS75: colors.black,
    V65: colors.red,
    V64: colors.orange,
    V5: colors.blue,
    V4: colors.green,
    V3: colors.blue,
    V: colors.green,
    VP: colors.green,
    DD: colors.green,
    LD: colors.green,
    TV: colors.green,
    T: colors.green,
    P: colors.green,
    K: colors.green,
    LK: colors.green,
    SK: colors.green,
    SOK: colors.green,
};

export const PRODUCTS_IMAGES = {
    V75: `url(${V75Bg}) ${colors.green}`,
    V86: `url(${V86Bg}) ${colors.green}`,
    GS75: `url(${GS75Bg}) ${colors.green}`,
    V65: `url(${V65Bg}) ${colors.green}`,
    V64: `url(${V64Bg}) ${colors.green}`,
    V5: `url(${V5Bg}) ${colors.green}`,
    V4: `url(${V4Bg}) ${colors.green}`,
    V3: colors.green,
    V: `url(${VinderBg}) ${colors.green}`,
    VP: `url(${VPBg}) ${colors.green}`,
    DD: `url(${DDBg}) ${colors.green}`,
    LD: `url(${LDBg}) ${colors.green}`,
    TV: `url(${TwinBg}) ${colors.green}`,
    T: `url(${TrioBg}) ${colors.green}`,
    P: `url(${PladsBg}) ${colors.green}`,
    K: `url(${kombBg}) ${colors.green}`,
    LK: colors.green,
    SK: colors.green,
    SOK: colors.green,
};

export const PRODUCTS_BACKGROUNDS: Record<string, string> = {
    GS75: `url(${bgGS75})`,
    V4: `url(${bgV45})`,
    V5: `url(${bgV45})`,
    V64: `url(${bgV64})`,
    V65: `url(${bgV65})`,
    V75: `url(${bgV75})`,
    V86: `url(${bgV86})`,
};
export const PRODUCTS_LABELS: Record<string, string> = {
    GS75: lGS75,
    V4: lV4,
    V5: lV5,
    V64: lV64,
    V65: lV65,
    V75: lV75,
    V86: lV86,
};

export const PRODUCT_IDS = {
    V75: 'V75',
    V86: 'V86',
    GS75: 'GS75',
    V65: 'V65',
    V64: 'V64',
    V5: 'V5',
    V4: 'V4',
    V3: 'V3',
    V: 'V',
    DD: 'DD',
    LD: 'LD',
    TV: 'TV',
    T: 'T',
    P: 'P',
    K: 'K',
    LK: 'LK',
    SK: 'SK',
    SOK: 'SOK',
    VP: 'VP',
};

export const PRODUCTS_PRIORITIES: Record<string, number> = {
    V75: 1,
    V86: 0,
    GS75: 2,
    V65: 3,
    V64: 4,
    V5: 5,
    V4: 6,
    V3: 7,
    V: 10,
    DD: 9,
    LD: 8,
    TV: 12,
    T: 13,
    P: 11,
    K: 14,
    LK: 15,
    SK: 16,
    SOK: 17,
    VP: 12,
};

export type ProductNameKey = keyof typeof PRODUCTS_NAMES;
export type ProductNameValue = (typeof PRODUCTS_NAMES)[ProductNameKey];

export const PRODUCTS_NAMES: Record<keyof typeof PRODUCT_IDS, string> = {
    V75: 'V75',
    V86: 'V86',
    GS75: 'GS75',
    V65: 'V65',
    V64: 'V64',
    V5: 'V5',
    V4: 'V4',
    V3: 'V3',
    V: 'Vinder',
    DD: 'DD',
    LD: 'LD',
    TV: 'Tvilling',
    T: 'Trio',
    P: 'Plads',
    K: 'Komb',
    LK: 'Lördags komb',
    SK: 'Special komb',
    SOK: 'Söndags komb',
    VP: 'V&P',
};

export const PRODUCTS_SHORTCUTS = [
    'V75',
    'V86',
    'GS75',
    'V65',
    'V64',
    'V6',
    'V5',
    'V4',
    'V',
    'DD',
    'LD',
    'TV',
    'T',
    'P',
    'K',
    'VP',
];

export const PRODUCTS_SYS_NAMES = {
    V75: 'V75',
    V86: 'V86',
    GS75: 'GS75',
    V65: 'V65',
    V64: 'V64',
    V5: 'V5',
    V4: 'V4',
    V3: 'V3',
    V: 'VP',
    DD: 'DD',
    LD: 'LD',
    TV: 'Tvilling',
    T: 'Trio',
    P: 'VP',
    K: 'Komb',
    LK: 'Komb',
    SK: 'Komb',
    SOK: 'Komb',
    VP: 'VP',
};
export const PRODUCTS_BET_NAMES = {
    V75: 'V75',
    V86: 'V86',
    GS75: 'GS75',
    V65: 'V65',
    V64: 'V64',
    V5: 'V5',
    V4: 'V4',
    V3: 'V3',
    V: 'VP',
    DD: 'DD',
    LD: 'LD',
    TV: 'TV',
    T: 'T',
    P: 'VP',
    K: 'Komb',
    LK: 'Komb',
    SK: 'Komb',
    SOK: 'Komb',
    VP: 'VP',
};

export const V_GAMES = [
    PRODUCTS_SYS_NAMES.V3,
    PRODUCTS_SYS_NAMES.V4,
    PRODUCTS_SYS_NAMES.V5,
    PRODUCTS_SYS_NAMES.V64,
    PRODUCTS_SYS_NAMES.V65,
    PRODUCTS_SYS_NAMES.V75,
    PRODUCTS_SYS_NAMES.V86,
    PRODUCTS_SYS_NAMES.DD,
    PRODUCTS_SYS_NAMES.LD,
    PRODUCTS_SYS_NAMES.GS75,
];

export const STRICT_V_GAMES = [
    PRODUCTS_SYS_NAMES.V3,
    PRODUCTS_SYS_NAMES.V4,
    PRODUCTS_SYS_NAMES.V5,
    PRODUCTS_SYS_NAMES.V64,
    PRODUCTS_SYS_NAMES.V65,
    PRODUCTS_SYS_NAMES.V75,
    PRODUCTS_SYS_NAMES.V86,
    PRODUCTS_SYS_NAMES.GS75,
];

export const DERBY_LYN_GAMES = [
    PRODUCTS_SYS_NAMES.V3,
    PRODUCTS_SYS_NAMES.V4,
    PRODUCTS_SYS_NAMES.V5,
    PRODUCTS_SYS_NAMES.V64,
    PRODUCTS_SYS_NAMES.V65,
    PRODUCTS_SYS_NAMES.V75,
    PRODUCTS_SYS_NAMES.V86,
    PRODUCTS_SYS_NAMES.GS75,
];

export const PERFORM_BET_API_ENDPOINTS = {
    [PRODUCT_IDS.V]: 'performWinShowBet',
    [PRODUCT_IDS.P]: 'performWinShowBet',
    [PRODUCT_IDS.TV]: 'performQuinellaBet',
    [PRODUCT_IDS.T]: 'performTrifectaBet',
    [PRODUCT_IDS.V4]: 'performV4Bet',
    [PRODUCT_IDS.V5]: 'performV5Bet',
    [PRODUCT_IDS.V64]: 'performV64Bet',
    [PRODUCT_IDS.V65]: 'performV65Bet',
    [PRODUCT_IDS.V75]: 'performV75Bet',
    [PRODUCT_IDS.GS75]: 'performGS75Bet',
    [PRODUCT_IDS.V86]: 'performV86Bet',
    [PRODUCT_IDS.LD]: 'performDoubleBet',
    [PRODUCT_IDS.DD]: 'performDoubleBet',
    [PRODUCT_IDS.K]: 'performKombBet',
    [PRODUCT_IDS.VP]: 'performWinShowBet',
};

export const BET_API_MARKS_STRING_LENGTH: Record<string, number> = {
    V75: 15,
    V86: 15,
    GS75: 15,
    V65: 15,
    V64: 15,
    V5: 15,
    V4: 20,
    V3: 15,
    V: 20,
    DD: 15,
    LD: 15,
    TV: 20, // Quinella
    T: 20, // Trifecta
    P: 20,
    K: 20, // Perfecta/Exacta
    LK: 20,
    SK: 20,
    SOK: 20, // Perfecta/Exacta special race Sundays
    VP: 20,
};

export const BET_API_MARKS_PROPERTY_NAMES: Record<string, string> = {
    V75: 'selected15Horses',
    V86: 'selected15Horses',
    GS75: 'selected15Horses',
    V65: 'selected15Horses',
    V64: 'selected15Horses',
    V5: 'selected15Horses',
    V4: 'selected15Horses',
    V3: 'selected15Horses',
    V: 'selected20Horses',
    DD: 'selected15Horses',
    LD: 'selected15Horses',
    TV: 'selected20Horses', // Quinella
    T: 'selected20Horses', // Trifecta
    P: 'selected20Horses',
    K: 'selected20Horses', // Perfecta/Exacta
    LK: 'selected20Horses',
    SK: 'selected20Horses',
    SOK: 'selected20Horses', // Perfecta/Exacta special race Sundays
    VP: 'selected20Horses',
};

export const POOL_OBJECT_KEYS = {
    [PRODUCT_IDS.V]: 'vpPoolInfo',
    [PRODUCT_IDS.TV]: 'tvillingPoolInfo',
    [PRODUCT_IDS.T]: 'trioPoolInfo',
    [PRODUCT_IDS.P]: 'vpPoolInfo',
    [PRODUCT_IDS.SOK]: 'vpPoolInfo',
    [PRODUCT_IDS.DD]: 'vpPoolInfo',
    [PRODUCT_IDS.K]: 'kombPoolInfo',
    [PRODUCT_IDS.VP]: 'vpPoolInfo',
};

export const POOL_TURNOVER_KEYS = {
    [PRODUCT_IDS.V]: 'turnoverVinnare',
    [PRODUCT_IDS.P]: 'turnoverPlats',
};

export const DEFAULT_SELECTED_PRODUCT = Object.assign(new Product(), {
    id: PRODUCT_IDS.V,
    name: PRODUCTS_NAMES.V,
    priority: PRODUCTS_PRIORITIES[PRODUCT_IDS.V],
    color: PRODUCTS_COLORS[PRODUCT_IDS.V],
    isMultitrack: false,
});

// There are list of rules for each product.
// "Races" property means a minimal number of races in the betting object.
export const PRODUCTS_BETTING_RULES: Record<string, { races: number } | false> = {
    V75: {
        races: 7,
    },
    V86: {
        races: 8,
    },
    GS75: {
        races: 7,
    },
    V65: {
        races: 6,
    },
    V64: {
        races: 6,
    },
    V5: {
        races: 5,
    },
    V4: {
        races: 4,
    },
    V3: {
        races: 3,
    },
    DD: {
        races: 2,
    },
    LD: {
        races: 2,
    },
    V: false,
    TV: false,
    T: false,
    P: false,
    K: false,
    LK: false,
    SK: false,
    SOK: false,
    VP: false,
};

export const MIN_COMBINATION_PRICES = {
    [PRODUCT_IDS.V]: 10,
    [PRODUCT_IDS.TV]: 5,
    [PRODUCT_IDS.T]: 2,
    [PRODUCT_IDS.V4]: 2,
    [PRODUCT_IDS.V5]: 1,
    [PRODUCT_IDS.V64]: 1,
    [PRODUCT_IDS.V65]: 1,
    [PRODUCT_IDS.V75]: 0.5,
    [PRODUCT_IDS.V86]: 0.25,
    [PRODUCT_IDS.GS75]: 1,
    [PRODUCT_IDS.LD]: 5,
    [PRODUCT_IDS.DD]: 5,
    [PRODUCT_IDS.P]: 10,
    [PRODUCT_IDS.K]: 5,
    [PRODUCT_IDS.VP]: 5,
};

export const DEFAULT_COMBINATION_PRICES = {
    ...MIN_COMBINATION_PRICES,
    [PRODUCT_IDS.T]: 5,
    [PRODUCT_IDS.V]: 20,
    [PRODUCT_IDS.P]: 20,
    [PRODUCT_IDS.VP]: 10,
};

export const MAX_COMBINATION_PRICES = {
    [PRODUCT_IDS.V4]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V4] * 100,
    [PRODUCT_IDS.V5]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V5] * 100,
    [PRODUCT_IDS.V64]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V64] * 100,
    [PRODUCT_IDS.V65]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V65] * 100,
    [PRODUCT_IDS.V75]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V75] * 100,
    [PRODUCT_IDS.GS75]: MIN_COMBINATION_PRICES[PRODUCT_IDS.GS75] * 100,
    [PRODUCT_IDS.V86]: MIN_COMBINATION_PRICES[PRODUCT_IDS.V86] * 100,
};

export const MAX_COUPON_AMOUNT = 99999;
export const MAX_SMART_LYN_COUPON_AMOUNT = 10001;
export const MAX_DERBY_LYN_COUPON_AMOUNT = 10001;

export const MIN_DERBY_LYN_COUPON_AMOUNT = 50;

export const MULTIPLE_TRACK_PRODUCTS = [PRODUCT_IDS.DD, PRODUCT_IDS.V86];

export const PRODUCTS_FOR_SPLITTING_BY_LEGS = [
    PRODUCT_IDS.DD,
    PRODUCT_IDS.V86,
    PRODUCT_IDS.V4,
    PRODUCT_IDS.V64,
    PRODUCT_IDS.T,
    PRODUCT_IDS.TV,
    PRODUCT_IDS.K,
];

export const COMBINATION_PRICE_SUGGESTIONS = {
    [PRODUCT_IDS.V75]: [0.5, 1, 2.5, 5, 25, 50],
    [PRODUCT_IDS.GS75]: [1, 2, 5, 10, 50, 100],
    [PRODUCT_IDS.V86]: [0.25, 0.5, 1.25, 2.5, 12.5, 25],
    [PRODUCT_IDS.V64]: [1, 2, 5, 10, 50, 100],
    [PRODUCT_IDS.V65]: [1, 2, 5, 10, 50, 100],
    [PRODUCT_IDS.V5]: [1, 2, 5, 10, 50, 100],
    [PRODUCT_IDS.V4]: [2, 4, 10, 20, 100, 200],
    [PRODUCT_IDS.DD]: [5, 10, 25, 50, 250, 500],
    [PRODUCT_IDS.LD]: [5, 10, 25, 50, 250, 500],
    [PRODUCT_IDS.V]: [10, 20, 50, 100, 200, 500],
    [PRODUCT_IDS.VP]: [10, 20, 50, 100, 200, 500],
    [PRODUCT_IDS.P]: [10, 20, 50, 100, 200, 500],
    [PRODUCT_IDS.K]: [10, 20, 50, 100, 200, 500],
    [PRODUCT_IDS.T]: [2, 4, 10, 20, 100, 200],
    [PRODUCT_IDS.TV]: [5, 10, 25, 50, 250, 500],
};

export const ACCEPTABLE_FLOAT_POINTS = {
    [PRODUCT_IDS.V75]: [5],
    [PRODUCT_IDS.V86]: [25, 5, 75],
};

export const SAVE_RACE_NUMBER_BETWEEN_PRODUCTS = [
    PRODUCT_IDS.V,
    PRODUCT_IDS.T,
    PRODUCT_IDS.TV,
    PRODUCT_IDS.P,
    PRODUCT_IDS.K,
    PRODUCT_IDS.VP,
];

export const HORSE_MARKS_SPLIT_BY_PLACES = [PRODUCT_IDS.T, PRODUCT_IDS.K];

export const COMBINATION_PRICE_EVEN_NUMBERS_ONLY = [PRODUCT_IDS.V4];

export const SMART_LYN_AMOUNTS = [50, 100, 200, 400, 600, 1000];

export const MERGED_POOLS_ORDER = [
    PRODUCT_IDS.V,
    PRODUCT_IDS.P,
    PRODUCT_IDS.TV,
    PRODUCT_IDS.T,
    PRODUCT_IDS.K,
];

export const HIDDEN_PRODUCTS = {};

export const PRODUCT_COLORS = {
    [PRODUCT_IDS.V86]: '#7D307D',
    [PRODUCT_IDS.V75]: '#10509F',
    [PRODUCT_IDS.GS75]: '#22624F',
    [PRODUCT_IDS.V65]: '#D01127',
    [PRODUCT_IDS.V64]: '#E96C16',
    [PRODUCT_IDS.V4]: '#74BCE9',
    [PRODUCT_IDS.V5]: '#74BCE9',
    [PRODUCT_IDS.DD]: '#74BCE9',
    [PRODUCT_IDS.LD]: '#74BCE9',
    [PRODUCT_IDS.V]: '#1CA963',
};

export const PRODUCT_COLORS_LIGHT = {
    [PRODUCT_IDS.V86]: '#d780d7',
    [PRODUCT_IDS.V75]: '#5b93de',
    [PRODUCT_IDS.GS75]: '#77bea5',
    [PRODUCT_IDS.V65]: '#f44e60',
    [PRODUCT_IDS.V64]: '#F0985C',
    [PRODUCT_IDS.V4]: '#9dd0f0',
    [PRODUCT_IDS.V5]: '#9dd0f0',
    [PRODUCT_IDS.DD]: '#9dd0f0',
    [PRODUCT_IDS.LD]: '#9dd0f0',
    [PRODUCT_IDS.V]: '#40B77C',
};

export const PRODUCT_HOVER_COLORS = {
    [PRODUCT_IDS.V86]: '#904F90',
    [PRODUCT_IDS.V75]: '#346AAD',
    [PRODUCT_IDS.GS75]: '#437969',
    [PRODUCT_IDS.V65]: '#D73447',
    [PRODUCT_IDS.V64]: '#EC8239',
    [PRODUCT_IDS.V4]: '#89C6EC',
    [PRODUCT_IDS.V5]: '#89C6EC',
    [PRODUCT_IDS.DD]: '#89C6EC',
    [PRODUCT_IDS.LD]: '#89C6EC',
};

export const QUICKPICK_ORDER = ['V75', 'V86', 'V64', 'V65', 'GS75', 'V5', 'V4'];

export const PRODUCT_TEXTS = {
    [PRODUCT_IDS.V]: `Du skal finde vinderen af det pågældende løb. Mindste indskud er 10 kroner.`,
    [PRODUCT_IDS.P]: `Du skal du finde en af de tre heste, der er først i mål. Er der tilmeldt under otte heste i løbet, skal du finde en af de to første heste i mål. Mindste indskud er 10 kroner `,
    [PRODUCT_IDS.TV]: `Du skal finde de to heste, der er først i mål. Mindste pris per kombination er 5 kroner. `,
    [PRODUCT_IDS.K]: `Du skal finde de to heste, der er først i mål i rigtige rækkefølge. Mindste pris per kombination er 5 kroner. `,
    [PRODUCT_IDS.T]: `Du skal finde de tre heste, der er først i mål i rigtige rækkefølge. Mindste pris per kombination er 5 kroner. `,
    [PRODUCT_IDS.V4]: `Du skal finde vinderne af fire forudbestemte løb. Mindste pris per kombination er 2 kroner `,
    [PRODUCT_IDS.V5]: `Du skal finde vinderne af fem forudbestemte løb. Mindste pris per kombination er 1 krone `,
    [PRODUCT_IDS.V64]: `Du skal finde vinderne af seks forudbestemte løb. Der er gevinst ved fire, fem og seks rigtige. Mindste pris per kombination er 1 kroner `,
    [PRODUCT_IDS.V65]: `Du skal finde vinderne af seks forudbestemte løb. Der er gevinst ved fem og seks rigtige. Mindste pris per kombination er 1 kroner `,
    [PRODUCT_IDS.V75]: `Du skal finde vinderne af syv forudbestemte løb. Der er gevinst ved fem, seks og syv rigtige. Mindste pris per kombination er 50 øre `,
    [PRODUCT_IDS.V86]: `Du skal finde vinderne af otte forudbestemte løb. Der er gevinst ved seks, syv og otte rigtige. Mindste pris per kombination er 25 øre `,
};

export const PRODUCT_COMPLEXITY = {
    [PRODUCT_IDS.V]: 1,
    [PRODUCT_IDS.TV]: 2,
    [PRODUCT_IDS.T]: 4,
    [PRODUCT_IDS.V4]: 4,
    [PRODUCT_IDS.V5]: 4,
    [PRODUCT_IDS.V64]: 5,
    [PRODUCT_IDS.V65]: 5,
    [PRODUCT_IDS.V75]: 6,
    [PRODUCT_IDS.V86]: 6,
    [PRODUCT_IDS.P]: 1,
    [PRODUCT_IDS.K]: 3,
    [PRODUCT_IDS.VP]: 5,
};
