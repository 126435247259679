import React from 'react';
import classNames from 'classnames';

import useColumnSelection from 'features/TrackPage/hooks/useColumnSelection';

import { BetPickBtn } from './PickButtons.styled';
import { isDesktop, isMobile } from 'utils/platforms';
import { trackEvent, trackingIDs } from 'utils/tracking';

const TrioPickColumnButtons = () => {
    const { checkColumnSelection, toggleColumnSelection } = useColumnSelection();
    const cls = classNames('flex col-gap-5 fade-in', {
        'pt-5': isMobile,
        'pb-5': isMobile,
        'pl-5': isMobile,
        'pl-15': isDesktop,
    });

    const handleToggleTrioColumn = (col: number) => {
        trackEvent({
            event: trackingIDs.racingCard.selectAllBtn,
            category: 'shortcuts',
            action: col,
        });

        toggleColumnSelection(col);
    };

    return (
        <div className={cls}>
            <BetPickBtn active={checkColumnSelection(1)} onClick={() => handleToggleTrioColumn(1)}>
                1
            </BetPickBtn>
            <BetPickBtn active={checkColumnSelection(2)} onClick={() => handleToggleTrioColumn(2)}>
                2
            </BetPickBtn>
            <BetPickBtn active={checkColumnSelection(3)} onClick={() => handleToggleTrioColumn(3)}>
                3
            </BetPickBtn>
        </div>
    );
};

export default TrioPickColumnButtons;
