import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';

import BetSlipContainer from 'features/BetSlip/BetSlipContainer';
import Receipt from 'features/BetSlip/components/Receipt/Receipt';
import QuickPickReceipt from 'features/QuickPick/QuickPickReceipt/QuickPickReceipt';
import BetslipHeader from 'features/BetSlip/platforms/Desktop/components/BetslipHeader';

import { styles, CloseButton, Content, Popup, TerminalPopup } from './styled';
import Icon from 'ui/Icon';
import Wrapper from 'ui/Wrapper';

import usePlaceSameVPBet from 'features/BetSlip/hooks/usePlaceSameVPBet';
import useBetslipVisibility from 'features/BetSlip/hooks/useBetslipVisibility';
import useCombinationPrice from 'features/BetSlip/hooks/useCombinationPrice';
import useSmartLyn from 'features/BetSlip/hooks/useSmartLyn';
import useSelections from 'features/BetSlip/state/useSelections';
import { resetBet, setDerbyLynBet, resetSmartLyn } from 'features/BetSlip/state/actions';
import withSmartLynManager from 'features/BetSlip/components/SmartLyn/withSmartLynManager';

import { byPlatform, IS_TERMINAL } from 'utils/platforms';
import { openLogin } from 'utils/navigation';

const BetslipDesktop = ({
    // from withRouter
    history,
    // from withSmartLynManager
    activateSmartLyn,
    // from props. Used for terminal
    onBetPerformed,
}) => {
    const { product: selectedProduct, combinationsCount, track, date } = useSelections();

    const dispatch = useDispatch();

    const ready = useSelector(
        state =>
            state.AISDataProvider.raceDayFetched &&
            state.AISDataProvider.racingCardFetched &&
            combinationsCount > 0
    );

    const [isBetslipHidden, setBetslipHidden, toggleBetslipVisibility] = useBetslipVisibility();

    const [combinationPrice, setCombinationPrice] = useCombinationPrice();

    const [isBetPerformed, setBetPerformed] = useState(false);

    const onSmartLynBetPerformed = () => {
        setBetPerformed(true);
    };

    const {
        smartLynMode,
        setSmartLynReceipt,
        setSmartLynBetPerformed,
        toggleSmartLynMode,
        isSmartLynBetPerformed,
        smartLynReceipt,
    } = useSmartLyn({
        activateSmartLyn,
        onSmartLynBetPerformed,
    });

    // const [playAndPayWorkflow, setPlayAndPayWorkflow] = useState(false);

    // betslip state clone that will be used for receipt
    const [betPick, setBetPick] = useState({});
    const [betResponse, setBetResponse] = useState({});

    const showReceipt = (betResponse, betPick) => {
        /* we are not showing receipt on terminals anymore*/
        if (IS_TERMINAL) {
            setBetslipHidden(true);
            dispatch(resetBet());
            reset();
            onBetPerformed && onBetPerformed();
            return;
        }

        if (smartLynMode) {
            setSmartLynReceipt(betResponse.data);
            setSmartLynBetPerformed(true);
        } else {
            setBetPick(betPick);
            setBetResponse(betResponse);
            setBetPerformed(true);
            setBetslipHidden(false);
        }
        dispatch(resetBet());
    };
    const reset = keepBetslipVisible => {
        if (!keepBetslipVisible) {
            setBetslipHidden(true);
        }
        setBetPerformed(false);
        setSmartLynBetPerformed(false);
    };

    const placeBetHandler = usePlaceSameVPBet({ reset });

    const placeLynBetHandler = (date, trackCode, product) => {
        reset();
        dispatch(setDerbyLynBet(date, trackCode, product));
        history.push('/derby-lyn');
    };

    const WrapperComponent = IS_TERMINAL ? TerminalPopup : Popup;

    return (
        <WrapperComponent
            isHidden={isBetslipHidden}
            fillBackground={!isBetPerformed}
            receiptShown={isBetPerformed}
            combinationsCount={combinationsCount}
        >
            {isBetPerformed && !IS_TERMINAL && (
                <CloseButton onClick={reset}>
                    <Icon iclose color="white" size="x05" />
                </CloseButton>
            )}
            {!isBetPerformed && !IS_TERMINAL && (
                <BetslipHeader
                    toggleBetslipVisibility={toggleBetslipVisibility}
                    isBetslipHidden={isBetslipHidden}
                    combinationPrice={combinationPrice}
                />
            )}
            <Scrollbars autoHeight universal={true} autoHeightMax={byPlatform(0, 700, 1000)}>
                <Content>
                    {(ready || IS_TERMINAL) && !isBetPerformed && (
                        <BetSlipContainer
                            styles={styles.BetSlip}
                            spinnerSize={'x05'}
                            toggleSmartLynMode={toggleSmartLynMode}
                            onCombinationPriceSet={setCombinationPrice}
                            onAuthenticationRequired={openLogin}
                            onBetPerformed={showReceipt}
                        />
                    )}
                    {isBetPerformed &&
                        (isSmartLynBetPerformed ? (
                            <Wrapper padding="30px 38px" align="center">
                                <QuickPickReceipt
                                    product={selectedProduct}
                                    track={track}
                                    date={date}
                                    legs={smartLynReceipt ? smartLynReceipt.coupon.legs : {}}
                                    receipt={smartLynReceipt}
                                    resetSmartLyn={() => dispatch(resetSmartLyn)}
                                />
                            </Wrapper>
                        ) : (
                            <Receipt
                                {...betPick}
                                styles={styles.Receipt}
                                combinationPrice={betResponse.coupon.combinationPrice / 100}
                                recipeMode={false}
                                placeBetHandler={placeBetHandler}
                                placeLynBetHandler={placeLynBetHandler}
                                product={selectedProduct}
                                totalCost={betResponse.receipt.totalCost}
                                // playAndPayWorkflow={playAndPayWorkflow}
                            />
                        ))}
                </Content>
            </Scrollbars>
        </WrapperComponent>
    );
};

export default withRouter(withSmartLynManager(BetslipDesktop));
