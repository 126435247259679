import { useEffectOnceWhen } from 'rooks';
import { generateEventPath, history } from 'utils/navigation';
import useURLParameters from './useURLParameters';
import useTrackPage from './useTrackPage';

const useAdjustATGLinks = () => {
    const { date, product, track: urlTrackName, race: raceNr } = useURLParameters();

    const { raceDay, calendarFetched } = useTrackPage();

    useEffectOnceWhen(() => {
        const queryString = new URL(window.location.href).search;

        // ATG uses track names for multitrack setups separated by dash in their URL's (e.g. 'bjerke-axevalla')
        // On the other hand, single track name also might contain dash, like some France tracks (e.g. Le Croise-Laroche),
        // hence the first check of the raceDay.trackName
        const singleTrackNameContainsDash = raceDay.trackName.includes('-');
        const shouldTransformMultitrackName =
            !singleTrackNameContainsDash && urlTrackName.includes('-');

        if (shouldTransformMultitrackName) {
            const [firstTrackName] = urlTrackName.split('-');

            history.replace(
                generateEventPath({
                    date,
                    productId: product,
                    track: firstTrackName,
                    // ATG might redirect to our site without race number parameter, so we have to add it on our side
                    race: raceNr || raceDay.nextSaleOpeRaceNumber || 1,
                }) + queryString
            );
        }
    }, calendarFetched);
};

export default useAdjustATGLinks;
