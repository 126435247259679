export interface ShoeInfo {
    foreShoes: boolean;
    hindShoes: boolean;
}

export interface ShoeInfoStart {
    startNumber: number;
    shoeInfoCurrent: ShoeInfo;
    shoeInfoPrevious: ShoeInfo;
}

export interface ShoeInfoRace {
    raceNumber: number;
    shoeInfoStart: {
        shoeInfoStart: ShoeInfoStart[];
    };
}

export interface TrackBetInfo {
    shoeInfoRace: {
        shoeInfoRace: ShoeInfoRace[];
    };
}

const shoeInfoStartPlaceholder = { raceNumber: 0, shoeInfoStart: { shoeInfoStart: [] } };

export function extractRaceShoeInfo(trackBetInfo: TrackBetInfo, raceNumber: number): ShoeInfoRace {
    return (
        trackBetInfo.shoeInfoRace.shoeInfoRace.find(
            shoeInfoRace => shoeInfoRace.raceNumber === raceNumber
        ) ?? { ...shoeInfoStartPlaceholder, raceNumber }
    );
}
