import usePrevious from 'common/hooks/usePrevious';
import { useEffect } from 'react';
import { isEqual, isObject } from 'lodash';
import { shallowEqual } from 'react-redux';

/**
 * Custom hook.
 * Calls a side effect once the value has changed
 *
 * @param {Function} sideEffect
 * @param {Array}    values
 * @param {Object}   options
 * @unstable
 */
const useChange = (
    sideEffect,
    values,
    options = {
        deep: false,
    }
) => {
    const { deep } = options;
    //  eslint-disable-next-line
    const prevValues = values.map(value => usePrevious(value));

    const valuesHaveAnyDifference = () =>
        values.some((value, i) => {
            const prevValue = prevValues[i];

            return areObjects(value, prevValue)
                ? !objectsEqual(value, prevValue)
                : value !== !prevValue;
        });

    const objectsEqual = (value, prevValue) => {
        return deep ? isEqual(value, prevValue) : shallowEqual(value, prevValue);
    };

    const areObjects = (value, prevValue) => isObject(value) && isObject(prevValue);

    useEffect(() => {
        if (valuesHaveAnyDifference()) {
            return sideEffect();
        } //  eslint-disable-next-line
    }, values);
};

export default useChange;
