import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Receipt from './Receipt';
import { setDerbyLynBet } from 'features/BetSlip2/state/actions';
import Modal from 'ui/ModalContent';
import { isMobile } from 'utils/platforms';

const ReceiptMobile = ({ history, modalData, hideModal, setDerbyLynBet }) => {
    useEffect(() => {
        history.listen(location => {
            if (location.pathname === '/mine-spil' && isMobile) {
                hideModal('RECEIPT');
            }
        });
    }, []);

    const placeLynBetHandler = (date, trackCode, product) => {
        hideModal('RECEIPT');
        setDerbyLynBet(date, trackCode, product);
        history.push('/derby-lyn');
    };

    return (
        <Modal topBarShown style={{ height: 'auto' }}>
            <Receipt
                {...modalData}
                hideModal={hideModal}
                recipeMode={true}
                placeLynBetHandler={placeLynBetHandler}
            />
        </Modal>
    );
};

const mapDispatchToProps = dispatch => ({
    setDerbyLynBet: (date, trackCode, product) => {
        dispatch(setDerbyLynBet(date, trackCode, product));
    },
});

export default withRouter(connect(undefined, mapDispatchToProps)(ReceiptMobile));
