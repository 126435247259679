import { useDispatch, useSelector } from 'react-redux';
import { getIn } from 'immutable';
import {
    selectColumn,
    addBet,
    clearAllSelections,
    resetRaceBet,
    removeBet,
    deselectColumn,
} from 'features/BetSlip2/state/actions';
import useTrackPage from './useTrackPage';
import { generateRaceKey } from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/utils';
import betSlipSelector from 'common/selectors/betSlipSelector';
import betTableSelector from 'common/selectors/betTableSelector';
import { ProductId } from 'features/EventBoard/server/calendar';
import Bet from 'common/DataObjects/Bet';
import { getHorseCountsInRaces } from '../model/Race';

const useColumnSelection = () => {
    const { raceDay, trackCode, race, productId, races } = useTrackPage();

    const dispatch = useDispatch();
    const selections = useSelector(betTableSelector);
    const betSlip = useSelector(betSlipSelector);

    //@ts-ignore
    const selectedColumns = useSelector(state => state.BetSlip.selectedColumns);

    const getBetValueArr = (
        productId: ProductId,
        startNr: number,
        existingSelections: Bet
    ): number[] => {
        const betValues = {
            TV: [
                startNr,
                // preserve U horses
                existingSelections.getUHorseNumbers().includes(startNr) ? 'U' : undefined,
            ].filter(item => item !== undefined),
            T: [1, 2, 3],
            K: [1, 2],
        };

        // @ts-ignore
        return typeof betValues[productId] === 'object' ? betValues[productId] : [startNr];
    };

    /**
     * @param {number} betPickColumn - Optional parameter which should be passed for Trio or Komb only
     */
    const checkSelection = (betPickColumn?: number) => {
        return getIn(
            selectedColumns,
            [generateRaceKey(raceDay.raceDayDate, trackCode, productId, race.index, betPickColumn)],
            false
        );
    };

    /**
     * @param {number} betPickColumn - Optional parameter which should be passed for Trio or Komb only
     */
    const toggleAllSelections = (betPickColumn?: number) => {
        let selectedStarts: Record<number, number[]> = {};

        if (checkSelection(betPickColumn)) {
            dispatch(resetRaceBet(raceDay.raceDayDate, trackCode, productId, race.index));
        } else {
            race?.horses?.forEach((horse, index) => {
                if (horse.vpPool.scratched) {
                    return;
                }

                dispatch(
                    addBet(
                        raceDay.raceDayDate,
                        trackCode,
                        productId,
                        race.index,
                        horse.startNr,
                        betPickColumn ?? index + 1
                    )
                );

                selectedStarts[horse.startNr] = getBetValueArr(
                    productId,
                    horse.startNr,
                    selections
                );
            });

            dispatch(
                selectColumn(raceDay.raceDayDate, trackCode, productId, race.index, betPickColumn)
            );
        }
    };

    const removeAllSelections = () => {
        dispatch(clearAllSelections());
    };

    /**
     * Used for Trio and Komb only.
     * For the other products we just clear the whole BetSlip state using CLEAR_ALL_SELECTIONS action.
     *
     * @param {number} betPickColumn
     */
    const removeColumnSelection = (betPickColumn: number) => {
        race?.horses?.forEach(horse => {
            dispatch(
                removeBet(
                    raceDay.raceDayDate,
                    trackCode,
                    productId,
                    race.index,
                    horse.startNr,
                    betPickColumn
                )
            );
        });
    };

    /**
     * Used for Trio and Komb only.
     * For the other products use toggleAllSelections function.
     *
     * @param {number} betPickColumn
     */
    const toggleColumnSelection = (betPickColumn: number) => {
        const columnPicked = checkSelection(betPickColumn);

        if (columnPicked) {
            dispatch(
                deselectColumn(raceDay.raceDayDate, trackCode, productId, race.index, betPickColumn)
            );

            return removeColumnSelection(betPickColumn);
        }

        toggleAllSelections(betPickColumn);
    };

    const combinationsCount = () =>
        betSlip.countCombinations({
            productId,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            horseCounts: getHorseCountsInRaces(races),
        });

    return {
        toggleAllSelections,
        removeAllSelections,
        isAllPicked: checkSelection(),
        checkColumnSelection: checkSelection,
        toggleColumnSelection,
        combinationsCount: combinationsCount(),
    };
};
export default useColumnSelection;
