import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'themes';
import { priority } from 'configs/layout';
import { clickableCornerIconMixin } from 'features/Spilklub/components/styled';
import { BetBuddyBar } from 'features/Spilklub/Mobile/styled.tsx';

import Text from 'ui/Text';
import Icon from 'ui/Icon';
import Fa from 'ui/Fa';

import { boxShadowMixin } from 'ui/Card';

import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';

const t = getTexts();

const CloseButton = styled(Icon).attrs({
    color: 'white',
})`
    height: 15px;
    width: 15px;
    padding: 20px;
    ${clickableCornerIconMixin};
`;

export const BackButton = styled(Fa).attrs({
    color: 'white',
    section: 'solid',
    svgName: 'angle-left',
})`
    height: 20px;
    width: 20px;
    padding: 10px;
    ${clickableCornerIconMixin};
`;

const ToolbarContainer = styled(BetBuddyBar)`
    height: 70px;
    background: ${COLORS.primary};
    ${boxShadowMixin};
`;

export const PaymentButtonsTitleContainer = styled.div`
    padding: ${byPlatform('20px 0px 10px 25px', '0px 0px 10px 25px')};
`;

export const PaymentButtonsContainer = styled.div`
    padding: 0 20px 20px 20px;
    gap: 0 15px;
`;

export const Input = styled.input`
    margin: 0 auto;
    height: 60px;
    width: 85%;
    display: block;
    padding: 0 20px;
    box-sizing: border-box;
    border: 1px solid #c6ccdb;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 900;
    color: ${COLORS.black};
    &::placeholder {
        color: #9198aa;
    }
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
`;

export const Toolbar = ({ goBack, close, title }) => (
    <ToolbarContainer>
        <BackButton onClick={goBack} style={{ zIndex: priority.topBar }} />
        <Text white center size={'x2'} bold>
            {title}
        </Text>
        <CloseButton onClick={close} iclose />
    </ToolbarContainer>
);
