import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isUserLoggedIn, logout } from 'common/actions/authActions';
import {
    setLastVisitedRaceURL,
    setLastURLBeforeLogin,
    showModal,
    showUnwrappedModal,
    setPreviousURL,
    setLoginTimeVisibility,
} from 'common/actions/uiActions';
import PersistentStorage from 'common/storage';
import UserConductor from 'common/conductors/UserConductor';
import { isAndroid, isMobile } from 'react-device-detect';
import { ModalsByURLSearchParams } from 'configs/modals';
import { isDesktop } from 'utils/platforms';
import { MODALS, SCREENS } from 'features/Spilklub/config';
import notifyAboutSelfExclusion from 'features/UserArea/SelfExclusion/notifyAboutSelfExclusion';
import getTexts from 'utils/localization';
import persistentStorage from 'common/storage/index';
import { isModalShown } from 'common/selectors/uiSelector';
import { history, showPopup } from 'utils/navigation';
import { getConfigByUrls } from 'configs/user-area';

const t = getTexts();

class SystemHandler extends React.Component {
    state = {
        userProfile: null,
    };

    firstLogin = userId => {
        if (!PersistentStorage.get('FIRST_LOGIN_' + userId)) {
            PersistentStorage.save({ ['FIRST_LOGIN_' + userId]: userId });
            this.props.showModal('OS_SHORTCUT', 3);
            // this.props.showModal('PIN_SETUP_LEAD', 2);
        }
    };

    handleModalsByURL = () => {
        const { queryParams } = this.props;
        const search = new URLSearchParams(queryParams);

        for (const param of search) {
            const [queryParam, value] = param;
            if (ModalsByURLSearchParams.hasOwnProperty(queryParam) && parseInt(value, 10) === 1) {
                const modalId = ModalsByURLSearchParams[queryParam].modalId;
                const data = ModalsByURLSearchParams[queryParam].data || {};
                const priority = ModalsByURLSearchParams[queryParam].priority || 0;

                this.props.showModal(modalId, priority, data);
            }
        }
    };

    componentDidMount() {
        const { auth } = this.props;

        window.addEventListener('SessionExpired', () => {
            this.props.clearUserState();
            !this.props.isLoginModalShown && this.props.showModal('SESSION_EXPIRED');
        });

        window.addEventListener('MitIdLoggedIn', () => {
            this.props.setLoginTimeVisibility(true);
        });

        window.addEventListener('DepositLimitExceed', e => {
            this.props.showModal('DEPOSIT_LIMIT_EXCEED', 3, {
                maxDeposit: e.detail.maxDeposit,
            });
        });

        window.addEventListener('SelfExclusionNote', () => {
            notifyAboutSelfExclusion();
        });

        window.addEventListener('ApplePaySuccess', () => {
            this.props.showModal('STATUS_OF_ACTION', 3, {
                success: true,
                title: t.userArea.deposit.success,
            });
        });

        window.addEventListener('ApplePayFailure', e => {
            const error = e.detail?.error;
            const fundError = t.userArea.deposit.fundErrors[error];

            this.props.showModal('STATUS_OF_ACTION', 3, {
                success: false,
                title: fundError || t.userArea.deposit.error,
            });
        });

        isAndroid &&
            window.addEventListener('beforeinstallprompt', event => {
                event.preventDefault();
                console.log('fire beforeinstallprompt event');
                if (
                    !(
                        window.matchMedia('(display-mode: standalone)').matches ||
                        window.navigator.standalone === true
                    ) &&
                    !persistentStorage.get('hidePWAPrompt')
                ) {
                    this.props.showModal('INSTALL_PWA_MODAL', 4, {
                        deferredPrompt: event,
                    });
                } else {
                    return false;
                }
            });

        isMobile &&
            auth.login.success &&
            UserConductor.getProfile().then(userProfile => this.setState({ userProfile }));

        this.props.isUserLoggedIn(this.props.auth.user);

        this.handleModalsByURL();

        if (isDesktop && history.location.pathname === '/spil-klub25') {
            this.props.showUnwrappedModal(MODALS.BET_BUDDY_ENTRY, {
                showScreens: [SCREENS.GROUPS],
                fromSystemHandler: true,
            });
        }

        if (isDesktop && history.location.pathname.toLowerCase().startsWith('/spk')) {
            showPopup('SPILKLUB_DESKTOP', {
                keepPathname: true,
                showScreens: [SCREENS.GROUPS],
                sharedLink: true,
            });
        }

        setInterval(() => {
            this.props.isUserLoggedIn(this.props.auth.user);
        }, 15000);
    }

    componentDidUpdate(prevProps) {
        const { auth } = this.props;
        const { userProfile } = this.state;

        if (auth.login.success && !prevProps.auth.login.success) {
            // session data has been fetched or a user has logged in
            //this.checkForUserAreaPopup();
        }

        if (this.props.pathname !== prevProps.pathname) {
            // any URL path was changed
            //this.checkForUserAreaPopup();
        }

        if (this.wentToLogin(prevProps)) {
            this.props.setLastURLBeforeLogin(prevProps.pathname);
        }

        if (this.leftRace(prevProps)) {
            this.props.setLastVisitedRaceURL(prevProps.pathname);
            persistentStorage.saveLS({ lastRaceURL: prevProps.pathname });
        }

        if (this.spilklubGroupForcedToLogin(prevProps)) {
            persistentStorage.saveLS({ spilklubGroupURL: prevProps.pathname });
        }

        if (
            this.hasURLChanged(prevProps) &&
            //@TODO Create and handle some blacklist config of the urls
            !prevProps.pathname.startsWith('/opret-konto')
        ) {
            this.props.setPreviousURL(prevProps.pathname);
        }

        if (
            auth.login.success &&
            !prevProps.auth.login.success &&
            auth.user &&
            userProfile &&
            userProfile.extraInfo.lastLogin === null
        ) {
            isMobile && this.firstLogin(auth.user.id);

            if (window.dataLayer) {
                window.dataLayer.push({ event: 'Login', userId: auth.user.id });
            }
        }

        if (this.props.queryParams !== prevProps.queryParams) {
            this.handleModalsByURL();
        }
    }

    checkForUserAreaPopup = () => {
        const path = history.location.pathname.replace('/', '');
        const userAreaPopupUrls = getConfigByUrls();

        if (userAreaPopupUrls[path]) {
            this.props.showUserAreaModal();
        }
    };

    wentToLogin = prevProps => {
        return (
            this.props.pathname !== prevProps.pathname &&
            prevProps.pathname !== '/log-ind' &&
            this.props.pathname === '/log-ind'
        );
    };

    leftRace = prevProps => {
        return (
            this.props.pathname !== prevProps.pathname &&
            prevProps.pathname.toLowerCase().startsWith('/spil/') &&
            !this.props.pathname.toLowerCase().startsWith('/spil/')
        );
    };

    spilklubGroupForcedToLogin = prevProps => {
        return (
            this.props.pathname !== prevProps.pathname &&
            prevProps.pathname.toLowerCase().startsWith('/spk') &&
            this.props.pathname.toLowerCase().startsWith('/log-ind')
        );
    };

    hasURLChanged = prevProps => {
        return this.props.pathname !== prevProps.pathname;
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state, { history }) => {
    return {
        pathname: history.location.pathname,
        auth: state.auth,
        isLoginModalShown: isModalShown(state, 'LOGIN'),
        queryParams: history.location.search,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isUserLoggedIn: () => {
            dispatch(isUserLoggedIn());
        },
        showModal: (ID, priority, data) => {
            dispatch(showModal(ID, priority, data));
        },
        showUserAreaModal: () => {
            dispatch(showModal('USER_AREA_MENU'));
        },
        showUnwrappedModal: (ID, data) => {
            dispatch(showUnwrappedModal(ID, data));
        },
        clearUserState: () => {
            dispatch(logout());
        },
        setLastVisitedRaceURL: raceURL => {
            dispatch(setLastVisitedRaceURL(raceURL));
        },
        setLastURLBeforeLogin: URL => {
            dispatch(setLastURLBeforeLogin(URL));
        },
        setPreviousURL: URL => {
            dispatch(setPreviousURL(URL));
        },
        setLoginTimeVisibility: loginTimeVisibility => {
            dispatch(setLoginTimeVisibility(loginTimeVisibility));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemHandler));
