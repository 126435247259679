import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import FlexWrapper from 'ui/FlexWrapper';
import getTexts from 'utils/localization';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { AnyRace } from 'features/TrackPage/model/Race';
const t = getTexts();

interface RaceInfoProps {
    race: AnyRace;
}

const RaceInfo = ({ race }: RaceInfoProps) => {
    const { raceDay, isMultitrack } = useTrackPage();
    const trackName = isMultitrack ? race.hostTrack?.domesticText : raceDay.trackName;

    return (
        <Wrapper
            padding="0px 45px"
            style={{ maxWidth: '300px', minWidth: '200px', margin: '0 auto' }}
        >
            <FlexWrapper padding="0px" direction="row" align="space-evenly">
                <Text size="14" align="center" bold>
                    {trackName}
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {race.trotRaceInfo && race.trotRaceInfo.startMethod
                        ? race.trotRaceInfo.monte
                            ? t.RaceType.monte
                            : t.RaceType.trot
                        : t.RaceType.gallop}
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                {race.gallopRaceInfo && race.gallopRaceInfo.trackSurface ? (
                    <Text size="14" align="center" bold>
                        {race?.gallopRaceInfo?.trackSurface?.code === 'gr'
                            ? 'Græs'
                            : race.gallopRaceInfo?.trackSurface?.domesticText ?? 'n/a'}
                    </Text>
                ) : (
                    <Text size="14" align="center" bold>
                        {race.trotRaceInfo && race.trotRaceInfo.startMethod
                            ? race.trotRaceInfo.startMethod.domesticText
                            : 'n/a'}
                    </Text>
                )}
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {race.distance}M
                </Text>
                <Text size="14" align="center" bold>
                    |
                </Text>
                <Text size="14" align="center" bold>
                    {t.RacingCard.race} {race.index + 1}
                </Text>
            </FlexWrapper>
        </Wrapper>
    );
};

export default RaceInfo;
