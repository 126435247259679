import { get, post } from './RequestHandler';
import { PAYMENT_SERVICE_ID } from 'configs/main';
import { getChannel } from 'utils/platforms';
import { handleUnauthorizedRequest } from 'utils/error.ts';

export default () => ({
    getUser: ({ silent = false } = {}) =>
        handleUnauthorizedRequest(get('getAccountInformation'), silent),

    login: (userName, password) =>
        post('channelLogin', { userName, password, channel: getChannel(), version: 2 }),

    isUserLoggedIn: () => get('isLoggedIn'),

    logout: () => post('logout'),

    getDepositLimits: () => handleUnauthorizedRequest(get('getDepositLimits')),

    setDepositLimits: limits => handleUnauthorizedRequest(post('setDepositLimits', limits)),

    getDepositBonuses: () =>
        handleUnauthorizedRequest(get(`getBonusListChannel/${PAYMENT_SERVICE_ID}`)),

    getPayout: () => handleUnauthorizedRequest(get('getWithdrawMaxAmountEx')),

    withdraw: amount =>
        handleUnauthorizedRequest(post('withdrawalRequestNem', { amount, text: 'derby' })),

    setPin: pin => handleUnauthorizedRequest(post('createHash', { pin })),

    pinLogin: (pin, hash, userName) =>
        handleUnauthorizedRequest(post('loginPin', { pin, hash, userName, version: 2 })),

    fetchHash: () => handleUnauthorizedRequest(get('fetchHash')),

    clearHash: () => handleUnauthorizedRequest(post('clearHash')),

    enableNewsletterSubscription: () =>
        handleUnauthorizedRequest(post('newsletterSubscription', { on: 1 })),

    setForcedPermission: payload => handleUnauthorizedRequest(post('derbyPermission', payload)),

    disableNewsletterSubscription: email =>
        handleUnauthorizedRequest(post('unsubscribeFromNewsletter', { email })),

    signup: {
        step1: cpr => post('signup_step1', { cpr }),
        step2: (result, signature) => post('signup_step2', { result, signature }),
        step34: UserData => post('signup_step3', { ...UserData }),
        storePunter: userData => post('storePunter', userData),
        // no parameters needed, it uses session to retrieve previously passed params to storePunter
        register: () => post('register'),
    },

    resetPassword: (username, email) => post('resetPassword', { userName: username, email }),

    getSecurityQuestionList: () => get('getSecurityQuestionList'),
    getExpectedBetting: () => get('getExpectedBetting'),

    getProfile: () => handleUnauthorizedRequest(get('getAccountInformation')),

    updateProfile: profile => handleUnauthorizedRequest(post('setAccountInformation', profile)),

    updatePassword: (currentPassword, newPassword, passwordConfirm) =>
        handleUnauthorizedRequest(
            post('changePassword', {
                current: currentPassword,
                edit: newPassword,
                confirm: passwordConfirm,
            })
        ),

    excludeUserDays: exclusionDays =>
        handleUnauthorizedRequest(post('excludeUserDays', { exclusionDays })),

    excludeUserPermanently: () => handleUnauthorizedRequest(post('excludeUserPermanently')),

    excludeUserByPeriod: exclusionPeriod =>
        handleUnauthorizedRequest(post('excludeUser', { exclusionPeriod })),

    getTransactionListAll: (dateFrom, dateTo, start, end) =>
        handleUnauthorizedRequest(
            get(`getTransactionAllList/${dateFrom}/${dateTo}/${start}/${end}`)
        ),
    getTransactionList: (dateFrom, dateTo) =>
        handleUnauthorizedRequest(get(`getTransactionList/${dateFrom}/${dateTo}`)),
    addTeam: (code, note) => post('addTeam', { code, note }),

    setExpectedBetting: expectBetting => post('setExpectedBetting', { expectBetting }),

    depositVoucher: (serial, code) => post('depositVoucher', { serial, code }),

    getUserStatus: () => get('getUserStatus'),

    checkEmail: (email, hash) => post('checkEmail', { email, hash }),

    checkUserName: (username, hash) => post('checkUsername', { username, hash }),
    // on: 0 / 1
    togglePermission: ({ on = 1 }) => post('derbyPermission', { on }),

    setRetailPin: ({ pin }) => post('setRetailPin', { retailPin: pin }),

    getActiveBonus: () => get('activeBonus'),
    getActiveBonusList: () => get('activeBonusList'),
    forfeitBonus: () =>
        post('forfeitBonus', {
            headers: { 'content-type': 'application/json' },
        }),

    payoutRetailCoupon: ({ couponId, saleId }) =>
        post('payoutRetailCoupon', { couponId, saleId, product: 'HS' }),
});
