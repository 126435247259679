import { MODALS, SCREENS } from '../config';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import useCoupon from './useCoupon';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSpilklubSelectionsModeTarget,
    isSpilklubSelectionsMode,
} from 'common/selectors/betSlipSelector';
import { isMobile } from 'utils/platforms';
import { showPopup } from 'utils/navigation';
import { deselectAllColumns } from 'features/BetSlip2/state/actions';

const useTrigger = () => {
    const dispatch = useDispatch();
    const { show } = useUnwrappedModals();
    const [updateCoupon] = useCoupon();
    const isSelectionsMode = useSelector(isSpilklubSelectionsMode);
    const selectionsModeTarget = useSelector(getSpilklubSelectionsModeTarget);

    return () => {
        if (!isSelectionsMode) {
            const modalProps = { showScreens: [SCREENS.BET_SLIP] };

            return isMobile
                ? show(MODALS.BET_BUDDY_ENTRY, modalProps)
                : showPopup('SPILKLUB_DESKTOP', modalProps);
        }

        dispatch(deselectAllColumns());

        const modalProps = {
            showScreens: [
                SCREENS.GROUPS,
                SCREENS.POOL_DASHBOARD,
                selectionsModeTarget === 'UPDATE'
                    ? SCREENS.FINAL_SELECTIONS
                    : SCREENS.COUPON_PREVIEW,
            ],
        };

        isMobile
            ? show(MODALS.BET_BUDDY_ENTRY, modalProps)
            : showPopup('SPILKLUB_DESKTOP', modalProps);
        //@ts-ignore
        selectionsModeTarget === 'UPDATE' && updateCoupon();
    };
};

export default useTrigger;
