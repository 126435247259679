import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, setComponentsOptions } from 'common/actions/uiActions';
import { RemoveBonusConfirm } from '@it25syv/25syv-ui';
import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { TransparentDesktopModalLayer } from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import useActiveBonusQuery from './hook/useActiveBonusQuery';

const RemoveBonusConfirmModal = ({ modalData }) => {
    const dispatch = useDispatch();
    const { refetch: refetchActiveBonusList } = useActiveBonusQuery();
    const { bonusAmount } = modalData;

    const removeBonus = () => {
        UserConductor.forfeitBonus()
            .then(res => {
                if (res && res.success) {
                    dispatch(hideModal('REMOVE_BONUS_CONFIRM'));
                    dispatch(setComponentsOptions({ resetBonusStatus: true }));
                    refetchActiveBonusList();
                } else {
                    NotificationConductor.error('Something went wrong');
                    dispatch(hideModal('REMOVE_BONUS_CONFIRM'));
                }
            })
            .catch(error => {
                dispatch(hideModal('REMOVE_BONUS_CONFIRM'));
                NotificationConductor.error(error.toString());
            });
    };
    return (
        <TransparentDesktopModalLayer>
            <FlexWrapper padding="0">
                <RemoveBonusConfirm
                    bonusAmount={bonusAmount.toString()}
                    onConfirm={removeBonus}
                    onCancel={() => {
                        dispatch(hideModal('REMOVE_BONUS_CONFIRM'));
                    }}
                />
            </FlexWrapper>
        </TransparentDesktopModalLayer>
    );
};

export default RemoveBonusConfirmModal;
