import { create } from 'zustand';

/**
 * betslip state clone that will be used for receipt, should include all BetslipContainer shared props
 */
const useBetslipPropsCopy = create(set => ({
    betslipPropsCopy: {},
    setBetslipPropsCopy: betslipSharedProps =>
        set(() => ({
            betslipPropsCopy: betslipSharedProps,
        })),
}));

export default useBetslipPropsCopy;
