import React from 'react';
import styled from 'styled-components';

import Icon from 'ui/Icon';
import Text from 'ui/Text';
import TopBar from 'ui/topbar';

import backBtnIcon from 'images/icons/chevron-back.svg';
import closeBtnIcon from 'images/icons/close-thin.svg';

const ShortTopBar = styled(TopBar)`
    height: 94px;
    width: 100%;
    min-height: auto;
    border-radius: 0;
    display: flex;
    justify-content: end;
    align-items: center;
`;

const TextWrapper = styled.div`
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

const NewTopBar = ({ goBack, close, children }) => {
    return (
        <ShortTopBar>
            <TextWrapper>
                <Icon onClick={goBack} src={backBtnIcon} margin={'0 0 0 16px'} />
                <Text bold size={'x1.5'} white flex={'none'} style={{ alignContent: 'center' }}>
                    {children}
                </Text>
                <Icon onClick={close} src={closeBtnIcon} margin={'0 16px 0 0'} />
            </TextWrapper>
        </ShortTopBar>
    );
};

export default NewTopBar;
