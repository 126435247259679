// There are the BASIC COLORS for derby25.dk

const COLORS = {
    primary: '#00A556',
    accent: '#ebcd32',
    secondary: '#3A4557',
    mutedText: '#818181',
    primaryText: '#333333',
    placeholderText: '#a7aec0',
    accentText: '#3C4758',
    secondaryText: '#B2B9C6',
    red: '#D13435',
    blue: '#0091D6',
    grey: '#404757',
    lightGreen: '#627b6f',
    lightGrey: '#dddddd',
    white: '#FFFFFF',
    dark: '#111111',
    black: '#1E1E1E',
    blockShadow: '#152ba334',
    overlay200: '#CDFAE3',
    overlay300: '#9CF0C6',
    special: {
        darkBlue: '#10579D',
        purple: '#8C3385',
        black: '#000000',
        red: '#F5393C',
        orange: '#EF8437',
        blue: '#6AB3E3',
        green: '#12AF6A',
        calm: '#ECEFF5',
    },
    borders: {
        neutral: ' #dfe5ed',
    },
};

export default COLORS;
