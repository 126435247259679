// There are the BASIC BACKGROUNDS COLORS for derby25.dk

const BACKGROUNDS = {
    primary: 'linear-gradient(to top, #04a358, #36b575)',
    primaryFlat: '#2CB16F',
    accent: '#FED804',
    accentAlt: '#FED300',
    secondary: '#3A4557',
    red: '#E54C4C',
    blue: '#2F82F4',
    grey: '#DFE3ED',
    lightGrey: '#ECEFF6',
    newLightGrey: '#F0F4FA',
    white: '#FFFFFF',
    black: '#111111',
    darkgreen: '#0e3622',
    none: 'none',
    navigation: '#18aa63',
    navigationActiveElement: '#1BBA6D',
    neutral: '#F5F8FC',
    hugeBlockGradient: `linear-gradient(
        to top right,
        #449853 0%,
        #44af6b 19%,
        #53ae78 52%,
        #3faa6a 70%,
        #469c5a 87%,
        #449853 100%
    )`,
};

export default BACKGROUNDS;
