import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/Icon';
import downloadIcon from 'images/icons/downloadpdf.svg';
import pdfIcon from 'images/icons/pdf-icon.svg';
import Text from 'ui/Text';
import { NEW_TRACK_PAGE } from 'configs/main';
import { COLORS } from 'themes';

interface DownloadSectionProps {
    onClick?(e: React.MouseEvent<HTMLElement>): any;
    title: string;
    description: string;
}

const Wrapper = styled.div`
    padding: ${NEW_TRACK_PAGE ? 0 : '0 20px 20px'};
    width: 100%;
    box-sizing: border-box;
    display: flex;
`;

const ContentWrapper = styled.div`
    background: ${COLORS.overlay200};
    flex: 1 0 88%;
    height: 57px;
    border-radius: 4px 0 0 4px;
    display: flex;
`;
const Content = styled.div`
    display: flex;
    padding: 10px;
    gap: 10px;
`;

const IconWrapper = styled.div`
    border-radius: 4px;
    padding: 4px;
    background: white;
    display: flex;
    align-items: center;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0;
`;
const Action = styled.div`
    flex: 1 0 12%;
    height: 57px;
    background: ${COLORS.overlay300};
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DownloadSection = ({ onClick, title, description }: DownloadSectionProps) => {
    return (
        <Wrapper>
            <ContentWrapper>
                <Content>
                    <IconWrapper>
                        <Icon src={pdfIcon} />
                    </IconWrapper>
                    <TextWrapper>
                        <Text padding={'0'} bold color={'#1A804D'} size={13} flex={'none'}>
                            {title}
                        </Text>
                        <Text padding={'0'} color={'#1A804D'} size={10} flex={'none'}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Text>
                    </TextWrapper>
                </Content>
            </ContentWrapper>
            <Action onClick={onClick}>
                <Icon src={downloadIcon} size={11} />
            </Action>
        </Wrapper>
    );
};

export default DownloadSection;
