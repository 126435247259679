import { useSelector } from 'react-redux';

import betSlipSelector from 'common/selectors/betSlipSelector';
import useTrackPage from './useTrackPage';
import { getHorseCountsInRaces } from '../model/Race';

const useCombinationsCount = () => {
    const { raceDay, trackCode, race, productId, races } = useTrackPage();

    const betSlip = useSelector(betSlipSelector);

    return betSlip.countCombinations({
        productId,
        trackCode,
        date: raceDay.raceDayDate,
        raceIndex: race.index,
        horseCounts: getHorseCountsInRaces(races),
    });
};

export default useCombinationsCount;
