import { HIDE_STREAM, SHOW_STREAM, START_STOP_CAST, SET_AUTOPLAY } from './actions';
import { getLS, saveLS } from 'common/storage/localStor';
import { isBool } from 'common/storage/validators';

const initialState = {
    isFloatingStreamShown: false,
    eventSource: '',
    isCastRunning: false,
    autoplay: getLS('StreamVideo.autoplay', isBool, false) === 'true',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_STREAM: {
            return {
                ...state,
                isFloatingStreamShown: true,
            };
        }
        case HIDE_STREAM: {
            return {
                ...state,
                isFloatingStreamShown: false,
            };
        }
        case START_STOP_CAST: {
            return {
                ...state,
                isCastRunning: action.payload,
            };
        }
        case SET_AUTOPLAY: {
            saveLS({ 'StreamVideo.autoplay': action.payload });

            return {
                ...state,
                autoplay: action.payload,
            };
        }
        default:
            return { ...state };
    }
};

export default reducer;
