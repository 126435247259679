import Race from './Race';
import Starts from './Starts';
import { POOL_OBJECT_KEYS, PRODUCT_IDS } from 'configs/products';
import moment from 'moment';
import maxBy from 'lodash/maxBy';

export default class RacingCard {
    trackCode = null;
    date = null;
    races = [];
    timestamp = null;
    _productId = null;

    /**
     * @return {Race[]}
     */
    getRacesWithIndexes = () => {
        return this.races.map((race, index) => ({ ...race, index }));
    };

    /**
     * @return {Race[]}
     */
    getStartedRaces = () => {
        return this.races.filter(race => !race.entriesReleased);
    };

    isNull = () => {
        return this.date === null || this.races.length === 0;
    };

    static unserialize(data, trackPool) {
        const racingCard = new RacingCard();

        try {
            racingCard.date = data.date;
            racingCard.timestamp = data.timestamp;
            racingCard.trackCode = data.track.code;
            racingCard._productId = data.betType.code;

            racingCard.races = data.races.races.map((item, index) =>
                Race.unserialize(
                    item,
                    trackPool,
                    racingCard._productId,
                    index,
                    data.track.code,
                    item.hostTrack
                )
            );

            const poolProductID =
                racingCard._productId === PRODUCT_IDS.T || racingCard._productId === PRODUCT_IDS.TV
                    ? PRODUCT_IDS.V
                    : racingCard._productId;

            if (
                trackPool &&
                trackPool[poolProductID] &&
                trackPool[poolProductID][POOL_OBJECT_KEYS[poolProductID]] &&
                trackPool[poolProductID][POOL_OBJECT_KEYS[poolProductID]].length !==
                    racingCard.races.length
            ) {
                trackPool[poolProductID][POOL_OBJECT_KEYS[poolProductID]].forEach((pool, index) => {
                    if (!racingCard.races.find(race => race.raceNumber === pool.raceNumber)) {
                        const prevRace =
                            index > 0 && racingCard.races[index - 1]
                                ? racingCard.races[index - 1]
                                : false;

                        const missedRace = new Race();

                        missedRace.cancelled = false;
                        missedRace.coupledHorsesInPool = false;
                        missedRace.distance = 0;
                        missedRace.entriesReleased = true;
                        missedRace.entryDate = prevRace.entryDate;
                        missedRace.postTime = moment(data.date + ' ' + prevRace.postTime)
                            .add(30, 'm')
                            .format('HH:mm:ss');
                        missedRace.postTimeUTC = moment(data.date + ' ' + prevRace.postTimeUTC)
                            .add(30, 'm')
                            .format('HH:mm:ss');
                        missedRace.raceNumber = pool.raceNumber;
                        missedRace.reservOrder = '';
                        missedRace.resultReleased = false;
                        missedRace.swedish = prevRace.swedish;
                        missedRace.trotRaceInfo = {};
                        missedRace.gallopRaceInfo = {};
                        missedRace.trackCode = prevRace.trackCode;
                        missedRace.index = pool.raceNumber;

                        const starts = [];

                        if (pool.vpOdds && pool.vpOdds.vpOdds) {
                            pool.vpOdds.vpOdds.forEach(item => {
                                const start = Starts.unserialize(
                                    {
                                        startNr: item.startNumber,
                                    },
                                    trackPool,
                                    pool.raceNumber,
                                    index,
                                    poolProductID
                                );

                                starts.push(start);
                            });
                        }

                        missedRace.starts = starts;

                        racingCard.races.push(missedRace);

                        racingCard.races = racingCard.races.sort((a, b) =>
                            a.raceNumber < b.raceNumber ? -1 : 1
                        );
                    }
                });
            }
        } catch (error) {
            //@TODO Logger of errors
            console.log(error);
        }

        return racingCard;
    }

    static fill(AISData, couple = false) {
        let racingCard = new RacingCard();
        racingCard = Object.assign(
            racingCard,
            !couple ? AISData.racingCardData : AISData.coupleRacingCard
        );
        racingCard._productId = AISData.selectedProduct.id;
        return racingCard;
    }

    static getForecastRace(races) {
        const racesWithForecast = races.filter(r => {
            const starts = r?.starts;
            return starts && starts.some(start => start?.forecastPossibleValue);
        });

        return maxBy(racesWithForecast, 'index');
    }
}
