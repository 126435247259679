import React from 'react';

import { Horse } from 'features/TrackPage/model/Race';
import Text from 'ui/Text';
import { byPlatform, isTerminal } from 'utils/platforms';
import { COLORS } from 'themes';

interface HorseNameProps {
    horse: Horse;
    showStartNumber: boolean;
}

export const Jockey = ({ horse }: { horse: Horse }) => (
    <Text
        weight={900}
        color={byPlatform(COLORS.primaryText, COLORS.primaryText, COLORS.black)}
        short
        padding="0px"
        size={byPlatform(12, 'x05', 16)}
        className={'capitalize'}
    >
        {horse.driver.name ? horse.driver.name : '--'}{' '}
        {horse.driver.surName ? horse.driver.surName : '--'}
    </Text>
);

const HorseName = ({ horse, showStartNumber }: HorseNameProps) => {
    return (
        <div className="flex flex-col">
            <div className="text-ellipsis">
                {showStartNumber && horse.startNr + '.'}{' '}
                {horse.horse.horseNameAndNationality?.toLowerCase() ?? '--'}
            </div>
            {!isTerminal && <Jockey horse={horse} />}
        </div>
    );
};

export default HorseName;
