import React, { createRef } from 'react';
import { TableColumn, TableHeader, TableRow } from 'ui/Table';
import styled from 'styled-components';
import Countdown from 'ui/Countdown';
import Wrapper, { FilledWrapper } from 'ui/Wrapper';
import Button from 'ui/controls/Button';
import { byPlatform, forMobile, isMobile } from 'utils/platforms';
import Icon from 'ui/Icon';
import Text from 'ui/Text';
import deletIcon from './assets/delete_derby.png';
import { connect } from 'react-redux';
import { showStream } from 'features/StreamVideo/actions';
import {
    resetSmartLyn,
    setDerbyLynBet,
    setSmartLynBet,
    setSmartLynMode,
} from 'features/BetSlip/state/actions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { buttonsColWidth } from 'features/Game';
import { BACKGROUNDS, COLORS } from 'themes';
import MassSelectionHOC from 'features/RaceCard/MassSelectionHOC';
import betSlipSelector from 'common/selectors/betSlipSelector';
import {
    getMultitrackCodes,
    getTrackFromRacingCard,
    getUITrackName,
} from 'common/selectors/trackSelector';
import raceDaySelector from 'common/selectors/raceDaySelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import withSmartLynManager from 'features/BetSlip/components/SmartLyn/withSmartLynManager';
import getTexts from 'utils/localization';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import { isLiveStreamAllowed } from 'common/selectors/auth';
import { selectedRaceJackpot } from 'common/selectors/poolOverviewSelector';
import { thousandsDots } from 'utils/formatters';
import { BetPickBtn } from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';
import { isCurrentRaceResulted } from 'common/selectors/racesSelector';

const t = getTexts();

const TextDiv = styled(Text).attrs({ as: 'div' })``;

const TrioMassSelectionButtons = styled(TableColumn)`
    padding-left: ${byPlatform('10px', '20px')};
    justify-content: flex-start;
`;

const SelectTrioRowButton = styled(BetPickBtn)`
    width: 38px;
    height: 38px;
    background: ${({ selected }) => (selected ? COLORS.primary : BACKGROUNDS.lightGrey)};
    color: ${({ selected }) => (selected ? COLORS.white : COLORS.black)};
    margin-right: ${byPlatform('4px', '10px')};
`;

const KombMassSelectionButtons = styled(TrioMassSelectionButtons)`
    padding-left: 15px;
`;

const SelectKombRowButton = styled(SelectTrioRowButton)`
    margin-right: 5px;
`;

const SpecialButtons = styled(TableColumn)`
    padding: 0;
    display: flex !important;
    flex-grow: ${props => props.grow};
    justify-content: flex-end;
`;

const SpecialBtn = styled(Button)`
    padding: 4px 6px;
    text-align: center;
    height: 38px;
    min-width: 38px;
    margin: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #333333;
    background: ${BACKGROUNDS.lightGrey};
    border: 0.5px solid #c6ccdb;
`;

const ReserveBtn = styled(SpecialBtn)`
    background: ${({ reserveMode }) => (reserveMode ? BACKGROUNDS.blue : BACKGROUNDS.lightGrey)};
    color: ${({ reserveMode }) => (reserveMode ? COLORS.white : '#333333')};
    border: ${({ reserveMode }) => (reserveMode ? 0 : '0.5px solid #C6CCDB')};
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 900;
`;

const JackpotLabel = styled.div`
    padding: 5px 7px;
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 3px;
    font-weight: 900;
    color: ${COLORS.black};
    font-size: 12px;
    margin-left: 7px;
    display: ${byPlatform(
        'flex !important',
        'inherit'
    )}; /* figure out why we have global style [display: inline !important] in Table.jsx */
    align-items: center;
`;

const StatsBtn = styled(SpecialBtn)`
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 900;
`;

const RightArea = styled.div`
    display: flex !important;
    justify-content: flex-end;
`;

const JackpotMobileRow = styled.div`
    padding: 5px 7px;
    background: ${BACKGROUNDS.accentAlt};
    font-weight: 900;
    height: 40px;
    color: ${COLORS.black};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlayerIcon = ({ innerRef, styles }) => <Icon play ref={innerRef} style={styles} />;

@MassSelectionHOC
@withSmartLynManager
@connect(state => ({
    hasResult: isCurrentRaceResulted(state),
}))
class RaceCardHeader extends React.Component {
    constructor() {
        super();

        this.state = {
            isStatisticActive: false,
        };

        this.playerBtnRef = createRef();
    }

    triggerStatistic = () => {
        if (this.props.onTriggerStatistic) {
            this.props.onTriggerStatistic(!this.state.isStatisticActive);
        }

        this.setState({
            isStatisticActive: !this.state.isStatisticActive,
        });
    };

    getSurface = () =>
        this.props.race.gallopRaceInfo.trackSurface.code === 'gr'
            ? 'GRÆS'
            : this.props.race.gallopRaceInfo.trackSurface.domesticText;

    showStream = () => {
        const { authorized, liveStreamAllowed, showStream } = this.props;

        authorized && liveStreamAllowed
            ? showStream()
            : NotificationConductor.warning(
                  !authorized ? t.Live.authErrorMessage : t.Live.restrictionMessage
              );
    };

    renderTime = () => {
        const { hasResult } = this.props;
        return (
            <time>
                <b className="text-bold">
                    {this.props.race.postTimeUTC === null ? (
                        '--:--:--'
                    ) : hasResult ? null : (
                        <Countdown
                            raceKey={this.props.race.trackCode + '-' + this.props.race.index}
                            onRaceFinished={this.props.onRaceFinished}
                            time={this.props.from}
                            timeUTC={this.props.postTimeUTC}
                            serverTime={this.props.serverTime}
                            withDay
                        />
                    )}
                </b>
            </time>
        );
    };

    render() {
        const {
            trio1stRowSelected,
            trio2ndRowSelected,
            trio3rdRowSelected,
            toggleTrio1stRow,
            toggleTrio2ndRow,
            toggleTrio3rdRow,
            komb1stRowSelected,
            komb2ndRowSelected,
            toggleKomb1stRow,
            toggleKomb2ndRow,
            product,
            toggleReserveMode,
            reserveModeEnabled,
            combinationsCount,
            jackpot,
        } = this.props;

        const showTrioSubheader = this.props.race.saleOpen && this.props.product.isTrio();
        const showKombSubheader = this.props.race.saleOpen && this.props.product.isKomb();

        return (
            <Wrapper padding="0px">
                <TableHeader
                    style={byPlatform(
                        {
                            position: 'relative',
                            alignItems: 'center',
                            height: 'auto',
                            minHeight: '30px',
                        },
                        {
                            position: 'relative',
                        }
                    )}
                    className="race-header"
                >
                    <div style={{ flex: '0 1 auto' }}>
                        <TextDiv
                            white
                            size={byPlatform(16, 16, 18)}
                            padding="0px"
                            bold
                            style={{
                                lineHeight: byPlatform('auto', 'inherit'),
                            }}
                            flex="0.1 1 auto"
                        >
                            {byPlatform(this.renderTime(), this.props.product.name)}

                            {!isMobile && jackpot > 0 && (
                                <JackpotLabel>
                                    Jackpot overført: {thousandsDots(jackpot)} kr.
                                </JackpotLabel>
                            )}
                        </TextDiv>
                        {/* @TODO: Player icon was here. Figure out, why we show this span for terminal */}
                        {byPlatform(null, null, <span />)}
                    </div>

                    {byPlatform(
                        null,
                        <div
                            style={{
                                flexGrow: 1,
                            }}
                        >
                            {' '}
                        </div>
                    )}

                    {byPlatform(
                        null,
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                width: '100%',
                                zIndex: -1,
                                fontSize: '16px',
                                fontWeight: '900',
                                wordSpacing: '7px',
                            }}
                        >
                            {this.props.trackName && this.props.trackName + '  |  '}
                            {this.props.race &&
                            this.props.race.trotRaceInfo &&
                            this.props.race.trotRaceInfo.startMethod
                                ? (this.props.race.trotRaceInfo.monte
                                      ? t.RaceType.monte
                                      : t.RaceType.trot) + '  |  '
                                : t.RaceType.gallop + '  |  '}
                            {this.props.race.gallopRaceInfo &&
                                this.props.race.gallopRaceInfo.trackSurface &&
                                this.getSurface() + '  |  '}
                            {this.props.race.trotRaceInfo &&
                                this.props.race.trotRaceInfo.startMethod &&
                                this.props.race.trotRaceInfo.startMethod.domesticText + '  |  '}
                            {this.props.race.distance} M {'  |  '}
                            {t.RacingCard.race} {this.props.race.raceNumber}
                        </div>
                    )}

                    {forMobile(
                        <RightArea style={{ height: '30px', flex: 1 }}>
                            <SpecialButtons
                                width="auto"
                                direction="row"
                                padding="0"
                                alignSelf="center"
                                flex
                                center
                                grow={1}
                            >
                                {product.canManuallyReserve() && (
                                    <ReserveBtn
                                        onClick={toggleReserveMode}
                                        size="x05"
                                        reserveMode={reserveModeEnabled}
                                    >
                                        Reserve heste
                                    </ReserveBtn>
                                )}

                                <StatsBtn onClick={this.triggerStatistic} size="x05" width="70px">
                                    {this.state.isStatisticActive ? 'Luk stats' : 'Åbn stats'}
                                </StatsBtn>
                                {combinationsCount > 0 && (
                                    <SpecialBtn
                                        size="x05"
                                        onClick={this.props.resetBet}
                                        style={{
                                            background: BACKGROUNDS.red,
                                        }}
                                    >
                                        <img
                                            src={deletIcon}
                                            alt="clear"
                                            style={{
                                                display: 'inline',
                                                height: '16px',
                                                padding: '0px 6px',
                                            }}
                                        />
                                    </SpecialBtn>
                                )}
                            </SpecialButtons>
                        </RightArea>
                    )}

                    {byPlatform(null, this.renderTime())}
                </TableHeader>

                {jackpot > 0 && isMobile ? (
                    <JackpotMobileRow>
                        Jackpot overført: {thousandsDots(jackpot)} kr.
                    </JackpotMobileRow>
                ) : null}

                {showTrioSubheader &&
                    forMobile(
                        <FilledWrapper
                            padding="5px 0"
                            white
                            style={{ borderBottom: byPlatform('1px solid #EBEFF6', 'none') }}
                        >
                            <TableRow align="space-between" direction="row" padding="0px">
                                <TrioMassSelectionButtons
                                    direction="row"
                                    alignSelf="center"
                                    grow={buttonsColWidth[this.props.product.id] || 0.1}
                                    flex
                                    center
                                >
                                    <SelectTrioRowButton
                                        selected={trio1stRowSelected}
                                        onClick={toggleTrio1stRow}
                                    >
                                        1
                                    </SelectTrioRowButton>
                                    <SelectTrioRowButton
                                        selected={trio2ndRowSelected}
                                        onClick={toggleTrio2ndRow}
                                    >
                                        2
                                    </SelectTrioRowButton>
                                    <SelectTrioRowButton
                                        selected={trio3rdRowSelected}
                                        onClick={toggleTrio3rdRow}
                                    >
                                        3
                                    </SelectTrioRowButton>
                                </TrioMassSelectionButtons>
                            </TableRow>
                        </FilledWrapper>
                    )}

                {showKombSubheader &&
                    forMobile(
                        <FilledWrapper
                            padding="5px 0"
                            white
                            style={{ borderBottom: byPlatform('1px solid #EBEFF6', 'none') }}
                        >
                            <TableRow align="space-between" direction="row" padding="0px">
                                <KombMassSelectionButtons
                                    direction="row"
                                    alignSelf="center"
                                    grow={buttonsColWidth[this.props.product.id] || 0.1}
                                    flex
                                    center
                                >
                                    <SelectKombRowButton
                                        selected={komb1stRowSelected}
                                        onClick={toggleKomb1stRow}
                                    >
                                        1
                                    </SelectKombRowButton>
                                    <SelectKombRowButton
                                        selected={komb2ndRowSelected}
                                        onClick={toggleKomb2ndRow}
                                    >
                                        2
                                    </SelectKombRowButton>
                                </KombMassSelectionButtons>
                            </TableRow>
                        </FilledWrapper>
                    )}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    authorized: state.auth.login.success,
    smartLynMode: state.BetSlip.smartLynMode,
    betSlip: betSlipSelector(state),
    date: raceDaySelector(state).date,
    trackCode: getTrackFromRacingCard(state).code,
    trackName: getUITrackName(state),
    jackpot: selectedRaceJackpot(state),
    selectedProduct: state.AISDataProvider.selectedProduct,
    derbyLynBet: state.BetSlip.derbyLynBet,
    races: state.AISDataProvider.racingCardData.races,
    auth: state.auth,
    serverTime: state.AISDataProvider.serverTime,
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    isMultitrack: isMultitrackProduct(state),
    multitrackCodes: getMultitrackCodes(state),
    combinationsCount: combinationsCountSelector(state),
    liveStreamTooltipShown: state.ui.components.showLiveStreamTooltip,
    liveStreamAllowed: isLiveStreamAllowed(state),
});

const mapDispatchToProps = dispatch => {
    return {
        showStream: () => {
            dispatch(showStream());
        },
        setSmartLynMode: enabled => {
            dispatch(setSmartLynMode(enabled));
        },
        resetSmartLyn: () => dispatch(resetSmartLyn()),
        setSmartLynBet: smartLynBet => {
            dispatch(setSmartLynBet(smartLynBet));
        },
        setDerbyLynBet: (date, trackCode, product) => {
            dispatch(setDerbyLynBet(date, trackCode, product));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaceCardHeader);
