import React from 'react';
import get from 'lodash/get';

import useTipsFromCMS from 'features/SpilTips/useTipsFromCMS';

import Text from 'ui/Text';
import {
    SpilTipsWrapper,
    TableRow,
    TableContent,
    TableHeader,
    TableCell,
    HorseName,
    HorseTip,
    MediaSourceLabel,
} from 'features/SpilTips/styled';
import { NEW_TRACK_PAGE } from 'configs/main';

const getHorseName = (race, horseNumber) =>
    get(race, [
        NEW_TRACK_PAGE ? 'horses' : 'starts',
        horseNumber - 1,
        'horse',
        'horseNameAndNationality',
    ]);

const CMSSpilTips = ({ raceIndex, race, product, productName, raceDay }) => {
    const { tips: horseTips } = useTipsFromCMS({ raceDay, product });
    const horseTipsByRace = horseTips[raceIndex] || [];

    return (
        <>
            {horseTipsByRace.length > 0 && (
                <SpilTipsWrapper>
                    <TableHeader>
                        <Text bold white size="16px" padding={'0'}>
                            Spiltips {productName}
                        </Text>
                    </TableHeader>
                    <TableContent isOpen={true}>
                        {horseTipsByRace.map(({ horseNumber, description }) => (
                            <TableRow>
                                <TableCell>
                                    <HorseName>{horseNumber} </HorseName>
                                </TableCell>
                                <TableCell>
                                    <HorseName>{getHorseName(race, horseNumber)} </HorseName>
                                    <HorseTip
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <MediaSourceLabel>Kilde: Trav24.dk</MediaSourceLabel>
                        </TableRow>
                    </TableContent>
                </SpilTipsWrapper>
            )}
        </>
    );
};

export default CMSSpilTips;
