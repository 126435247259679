import React from 'react';
import { useDispatch } from 'react-redux';

import VRaceCardHeader from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/VRaceCardHeader';
import VRaceSubHeader from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/VRaceSubHeader';
import VGameHeader from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/VGameHeader';
import VGameCard from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/VGameCard';
import { clearAllSelections } from 'features/BetSlip2/state/actions';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { hasResultByRaceNr } from 'features/EventBoard/server/calendar';

import { VRaceOverviewContainer } from './styled';

const VRaceOverview = () => {
    const { races, raceDay, product, race, isVStrictProduct, racesLoading, racesError } =
        useTrackPage();

    const dispatch = useDispatch();
    const clearAllPicks = () => dispatch(clearAllSelections());

    if (racesLoading || racesError) {
        return null;
    }

    const [firstRace] = races;
    const startDateTime = raceDay.raceDayDate + ' ' + firstRace.postTime;
    const startDateTimeUTC = raceDay.raceDayDate + ' ' + firstRace.postTimeUTC;
    const hasResult = hasResultByRaceNr(raceDay, race.raceNumber);

    return (
        <VRaceOverviewContainer>
            <VRaceCardHeader
                from={startDateTime}
                race={firstRace}
                postTimeUTC={startDateTimeUTC}
                product={product}
                hasResult={hasResult}
            />
            <VRaceSubHeader isVStrictProduct={isVStrictProduct} clearAllPicks={clearAllPicks} />
            <VGameHeader />
            <VGameCard
            //raceStarted={this.state.raceStarted}
            />
        </VRaceOverviewContainer>
    );
};

export default VRaceOverview;
