import React from 'react';
import DKRouter from '../routers/dk-router';
import { withRouter } from 'react-router-dom';
import Wrapper from '../ui/Wrapper';
import DesktopModals from './sections/DesktopModals';
import FullWidthTopBar from './sections/FullWidthTopBar';
import Footer from './sections/Footer';
import SystemHandler from 'features/SystemHandler';
import Highlights from 'layouts/sections/Highlights';
import BetSlipPopup from 'features/BetSlip/platforms/Desktop';
import BetSlipPopup2 from 'features/BetSlip2/platforms/Desktop';
import blobLeft from 'images/dk/left_blob_web_trans1.png';
import blobRight from 'images/dk/right_blob_web_trans1.png';
import { NEW_TRACK_PAGE, SET_FULL_WIDTH_FOR_PATH } from 'configs/main';
import styled from 'styled-components';
import { intercomBoot } from 'utils/trackers';
import { showStream, setStreamAutoplay } from 'features/StreamVideo/actions';
import { connect } from 'react-redux';
import PLayStreamButton from 'features/StreamVideo/PlayButton';
import BetBuddyButton from 'features/Spilklub/Desktop/Trigger';
import BetBuddyButton2 from 'features/Spilklub2/Desktop/Trigger';
import { pageContainerWidth, sidebar } from 'configs/layout';
import DesktopSidebar from './sections/DesktopSidebar';
import { isTerminal } from 'utils/platforms';
import ProductsTransitionBar, { WhiteSpaceFixer } from 'layouts/sections/ProductsTransitionBar';
import ReactTooltip from 'react-tooltip';
import { checkURLs } from 'utils/navigation';
import { trackEvent, trackingIDs } from 'utils/tracking';
import { isLiveStreamAllowed } from 'common/selectors/auth';

import getTexts from 'utils/localization';
import LoginTimePopup from '../ui/LoginTimePopup';
const t = getTexts();

const Backgrounds = styled(Wrapper)`
    margin: 0;
    padding: 0;
    background-color: #f0f2f8;
    .tooltip {
        opacity: 1 !important;
        font-size: 14px;
        font-weight: bold;
        color: #505050;
    }
`;

class Desktop extends React.Component {
    constructor() {
        super();

        this.state = {
            isLeftSideBarOpen: false,
        };
    }

    componentDidMount() {
        let prevLocation = ''; // needed because of multiple calls of history.push inside navigateToRaceDay
        const pushPageView = () =>
            window.dataLayer.push({
                event: 'page_view',
                eventUrl: window.location.pathname,
                type: 'heste',
                userId: this.props?.userId || 0,
            });

        this.props.history.listen(location => {
            if (window.dataLayer) {
                if (prevLocation !== location.pathname) {
                    pushPageView();
                }
                prevLocation = location.pathname;
            }
        });

        //on first mount, setInterval needs if user is logged in
        const interval = setInterval(() => {
            if (!this.props.authPending) {
                if (window.dataLayer) {
                    pushPageView();
                    prevLocation = this.props?.history?.location?.pathname;
                }
                clearInterval(interval);
            }
        }, 1000);

        if (window.self === window.top) {
            intercomBoot();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.authorized && this.props.authorized) {
            setTimeout(this.props.showStream(), 0);
        }
    }

    openLiveStream = () => {
        trackEvent({
            event: trackingIDs.liveStreamBtn,
            category: 'shortcuts',
            action: t.liveStream,
        });
        this.props.setStreamAutoplay(true);
        this.props.showStream();
    };

    UNSAFE_componentWillMount() {
        let isLeftSideBarOpen =
            window.localStorage.getItem('isLeftSideBarOpen') == null
                ? true
                : window.localStorage.getItem('isLeftSideBarOpen') === 'true';
        this.setState({ isLeftSideBarOpen: isLeftSideBarOpen });
    }

    onBurgerClickHandler() {
        window.localStorage.setItem(
            'isLeftSideBarOpen',
            (!this.state.isLeftSideBarOpen).toString()
        );
        this.setState({ isLeftSideBarOpen: !this.state.isLeftSideBarOpen });
    }

    render() {
        const setFullWidthForThisPath = checkURLs(SET_FULL_WIDTH_FOR_PATH);

        const highlights = this.props.showHighlight ? <Highlights /> : null;

        return (
            <Backgrounds className="desktop-layout" blobLeft={blobLeft} blobRight={blobRight}>
                <ReactTooltip
                    border={true}
                    borderColor="#70707033"
                    className="tooltip"
                    type="light"
                    place="bottom"
                    effect="solid"
                />
                <DesktopSidebar
                    isOpen={this.state.isLeftSideBarOpen}
                    onBurgerClickHandler={this.onBurgerClickHandler.bind(this)}
                />
                <WhiteSpaceFixer>
                    {!isTerminal && (
                        <ProductsTransitionBar
                            background={'#299648'}
                            secondColor={'#2CBF78'}
                            isLeftSideBarOpen={this.state.isLeftSideBarOpen}
                        />
                    )}
                    <FullWidthTopBar
                        key={window.location.pathname}
                        onBurgerClickHandler={this.onBurgerClickHandler.bind(this)}
                        isLeftSideBarOpen={this.state.isLeftSideBarOpen}
                    />
                </WhiteSpaceFixer>

                <div
                    style={{
                        marginLeft: this.state.isLeftSideBarOpen
                            ? `${sidebar.width}px`
                            : `${sidebar.widthClosed}px`,
                        transition: 'all 200ms ease',
                    }}
                >
                    <Wrapper block padding={`62px 0 0 0`} margin="0 auto">
                        {highlights}
                        <Wrapper
                            block
                            padding="0"
                            maxWidth={setFullWidthForThisPath ? 'auto' : pageContainerWidth}
                            margin="0 auto"
                        >
                            <DKRouter />
                        </Wrapper>
                    </Wrapper>
                </div>

                {/*<Footer isOpen={this.state.isLeftSideBarOpen} />*/}
                {<Footer isOpen={this.state.isLeftSideBarOpen} />}

                {/* Modals */}
                <DesktopModals />
                {/*{this.props.betBuddyShown && <BetBuddyEntry />}*/}
                <SystemHandler />
                {!this.props.betBuddySelectionsMode &&
                    (NEW_TRACK_PAGE ? <BetSlipPopup2 /> : <BetSlipPopup />)}

                {NEW_TRACK_PAGE ? (
                    <BetBuddyButton2 />
                ) : this.props.isVProduct ? (
                    <BetBuddyButton />
                ) : null}

                {this.props.authorized && this.props.liveStreamAllowed ? (
                    <PLayStreamButton playStream={this.openLiveStream} />
                ) : null}

                <LoginTimePopup />
            </Backgrounds>
        );
    }
}
const mapStateToProps = state => ({
    authorized: state.auth.login.success,
    authPending: state.auth?.pending,
    userId: state.auth?.user?.id,
    //betBuddyShown: betBuddyShown(state),
    betBuddySelectionsMode: state.BetSlip.betBuddySelectionsMode.status,
    liveStreamAllowed: isLiveStreamAllowed(state),
    showHighlight: state.ui.showHighlight,
});
const mapDispatchToProps = dispatch => {
    return {
        showStream: () => {
            dispatch(showStream());
        },
        setStreamAutoplay: status => {
            dispatch(setStreamAutoplay(status));
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Desktop));
