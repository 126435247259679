export function getAuth(state) {
    return state.auth;
}
export function user(state) {
    return getAuth(state).user;
}

export function isPinSet(state) {
    return !!(state.auth.pin.hash && state.auth.pin.userName);
}

const FREE_BET_TYPE_HESTE = 0;

export function isFreeBetAvailable(state) {
    return user(state)?.freeBet > 0 && user(state)?.freeBetType === FREE_BET_TYPE_HESTE;
}

export function isLiveStreamAllowed(state) {
    const usr = user(state);

    if (!usr) {
        return false;
    }

    if (!usr.lastLogin) {
        return true;
    }

    return usr.depositMade || usr.newsletter;
}
