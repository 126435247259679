import useRacePicks, { BetPickModifier } from './useRacePicks';
import { AnyRace, Horse } from '../model/Race';
import useReservesMode from './useReservesMode';

import NotificationConductor from 'common/conductors/NotificationConductor';

const showNotificationScratchedHorse = () => {
    NotificationConductor.info('Hesten er udgået');
};

interface Params {
    horse: Horse;
    race: AnyRace;
}

const useToggleHorseSelection = ({ horse, race }: Params) => {
    const { reservesMode } = useReservesMode();

    const raceFinished = !race.saleOpen || race.poolClosed;
    const scratched = horse.vpPool.scratched;

    const { pickStart, unpickStart, addReserve, removeReserve, isPicked, reserved } = useRacePicks({
        startNr: horse.startNr,
        race,
    });

    const toggleSelection = (betPickModifier?: BetPickModifier) => {
        if (raceFinished) {
            return;
        }

        const picked = isPicked(betPickModifier);

        if (scratched && !picked) {
            return showNotificationScratchedHorse();
        }

        if (reservesMode) {
            reserved ? removeReserve() : addReserve();
        } else {
            picked ? unpickStart(betPickModifier) : pickStart(betPickModifier);
        }
    };

    return { toggleSelection };
};

export default useToggleHorseSelection;
