import { PRODUCT_IDS } from 'configs/products';
import { AnyRace } from 'features/TrackPage/model/Race';
import { ProductId } from 'features/EventBoard/server/calendar';

export const shouldShowStartNrWithName = (race: AnyRace, productId: ProductId) => {
    if (!race.poolClosed && productId === PRODUCT_IDS.TV) {
        // shouldn't be shown for open Tvilling pools
        return false;
    }
    return [PRODUCT_IDS.T, PRODUCT_IDS.K, PRODUCT_IDS.TV].includes(productId);
};

export const shouldHideNrCol = (race: AnyRace, productId: ProductId) =>
    race.poolClosed && shouldShowStartNrWithName(race, productId);
