import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { history, generateEventPath } from 'utils/navigation';

import { ProductId } from 'features/EventBoard/server/calendar';

import ProductButtons from './ProductButtons';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';
import { Label } from 'features/TrackPage/components/styled';
import { SAVE_RACE_NUMBER_BETWEEN_PRODUCTS } from 'configs/products';
import { NEW_TRACK_PAGE } from 'configs/main';

import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';
import {
    resetBetBuddySelections,
    resetBetBuddySelectionsMode,
} from 'features/BetSlip2/state/actions';
import { isAnyRaceOngoing } from 'features/TrackPage/model/Race';

const ProductsRow = () => {
    const {
        calendarLoading,
        racesLoading,
        productId: selectedProductId,
        raceDay,
        races,
        refetchPoolQuery,
    } = useTrackPage();

    const { date, track, race } = useURLParameters();

    const dispatch = useDispatch();
    const spilklubSelectionsMode = useSelector(isSpilklubSelectionsMode);

    const showLabels = !isMobile;

    const onSelectProduct = (productId: ProductId) => {
        const shouldKeepRaceNumber =
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(selectedProductId) &&
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(productId);

        const newRaceValue = shouldKeepRaceNumber ? race : 1;

        if (spilklubSelectionsMode) {
            dispatch(resetBetBuddySelections());
            dispatch(resetBetBuddySelectionsMode());
        }

        if (isAnyRaceOngoing(races, raceDay.raceDayDate)) {
            // Some data might change when certain races start on the track,
            // e.g., race forecasts or odds might be outdated by the time a user switches between products.
            refetchPoolQuery();
        }

        history.push(generateEventPath({ productId, date, track, race: newRaceValue }));
    };

    return (
        <>
            {showLabels && <Label>Spiltype</Label>}

            <ProductButtons
                data={raceDay.fullProductList}
                loading={calendarLoading}
                selectedProductId={selectedProductId}
                onSelect={onSelectProduct}
                disabled={racesLoading}
                legacyMode={!NEW_TRACK_PAGE}
            />
        </>
    );
};

export default ProductsRow;
