import styled from 'styled-components';
import { COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';
import { TableColumn } from 'ui/Table';

const ColStarts = styled(TableColumn)`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    text-align: left;
    max-width: 100%;
`;

const HorseCol = styled(TableColumn)`
    text-align: ${({ center }) => (center ? `center` : `left`)};
    font-size: 16px;
    font-weight: 600;
    color: ${COLORS.black};
    text-overflow: ${({ short }) => (short ? `ellipsis` : `inherit`)};
    overflow: hidden;
    width: auto;
`;

const ShoeCol = styled.span`
    font-family: Roboto, sans-serif;
    font-weight: 400;
`;

const TwinButtonsWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    position: relative;
    height: 100%;
`;

const GameHeaderCol = styled(TableColumn)`
    font-size: ${byPlatform('12px', '10px')};
    font-weight: 900;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${props => props.width ?? 'auto'};
    ${props =>
        props.textCenterMobile &&
        `
          @media(max-width: 640px) {
            text-align: center;
          }
        `}
`;

const GameHeaderColCenter = styled(GameHeaderCol)`
    text-align: center;
`;

const GameHeaderColIcon = styled(GameHeaderCol)`
    display: flex;
    align-items: center;
`;

const GameHeaderColIconCenter = styled(GameHeaderColIcon)`
    text-align: center;
`;

const StatsIcon = styled.img`
    width: 16px;
    height: 16px;
`;

export {
    ColStarts,
    HorseCol,
    TwinButtonsWrapper,
    GameHeaderCol,
    GameHeaderColCenter,
    GameHeaderColIcon,
    GameHeaderColIconCenter,
    ShoeCol,
    StatsIcon,
};
