import { useSelector, useDispatch } from 'react-redux';
import { getModals, getModalStatuses } from '../selectors/uiSelector';
import { showModal, hideModal, hideModalAnimate } from '../actions/uiActions';

const useModals = () => {
    const dispatch = useDispatch();
    const modals = useSelector(getModalStatuses);
    const modalsData = useSelector(getModals);

    const isShown = (modalId: string) => modals[modalId];
    const show = (modalId: string, priority: number = 0, data?: Object, duration?: number) =>
        dispatch(showModal(modalId, priority, data, duration));
    const hide = (modalId: string) => dispatch(hideModal(modalId));
    const animateHide = (modalId: string, duration?: number, meta?: any) =>
        dispatch(hideModalAnimate(modalId, duration, meta));
    const getData = (modalId: string) => (modalsData[modalId] ? modalsData[modalId].data : {});

    return { modals, isShown, show, hide, animateHide, getData };
};

export default useModals;
