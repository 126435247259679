import { useDispatch } from 'react-redux';
import { addBet } from 'features/BetSlip2/state/actions';
import { PRODUCT_IDS } from 'configs/products';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

/**
 * Used in receipt for placing same Vinder or Plads bet
 * (either P after V or V after P accordingly)
 *
 * @return {function(...[*]=)}
 */
const useSameVPBetPicks = () => {
    const dispatch = useDispatch();

    const { raceDay, product, trackCode, race } = useTrackPage();

    function placeVinderPladsBetFromRecipt({ betSlipCopy }) {
        try {
            let betForProduct = PRODUCT_IDS.P;

            if (product.id === PRODUCT_IDS.P) {
                betForProduct = PRODUCT_IDS.V;
            }

            if (
                betSlipCopy.betsByDates &&
                betSlipCopy.betsByDates[raceDay.raceDayDate] &&
                betSlipCopy.betsByDates[raceDay.raceDayDate][trackCode]
            ) {
                Object.keys(
                    betSlipCopy.betsByDates[raceDay.raceDayDate][trackCode][product.id][race.index]
                ).forEach(startNr => {
                    dispatch(
                        addBet(
                            raceDay.raceDayDate,
                            trackCode,
                            betForProduct,
                            parseInt(race.index, 10),
                            parseInt(startNr, 10),
                            parseInt(startNr, 10)
                        )
                    );
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return placeVinderPladsBetFromRecipt;
};

export default useSameVPBetPicks;
