import React from 'react';
import { useDispatch } from 'react-redux';

import Clock from '../common/Clock';
import { RaceDay } from 'features/EventBoard/server/calendar';
import { AnyRace } from 'features/TrackPage/model/Race';
import useCombinationsCount from 'features/TrackPage/hooks/useCombinationsCount';
import { clearAllSelections } from 'features/BetSlip2/state/actions';

import getTexts from 'utils/localization';

import { BACKGROUNDS } from 'themes';
import { RaceCardHeaderContainer, HeaderButton, ClearAllBtn } from './styled';

import deleteSelectionsIcon from 'images/icons/delete-selections.png';

const t = getTexts();

interface RaceCardHeaderProps {
    raceDay: RaceDay;
    race: AnyRace;
    reservesMode: boolean;
    toggleReservesMode(): void;
    isVStrictProduct: boolean;
    statsExpanded: boolean;
    toggleStats(): void;
}

const RaceCardHeader = ({
    raceDay,
    race,
    toggleReservesMode,
    reservesMode,
    isVStrictProduct,
    statsExpanded,
    toggleStats,
}: RaceCardHeaderProps) => {
    const combinationsCount = useCombinationsCount();

    const dispatch = useDispatch();

    const handleClearAllSelections = () => dispatch(clearAllSelections());

    return (
        <RaceCardHeaderContainer>
            <Clock raceDay={raceDay} race={race} />

            <div className="flex gap-5">
                {isVStrictProduct && (
                    <HeaderButton active={reservesMode} onClick={toggleReservesMode}>
                        Reserve heste
                    </HeaderButton>
                )}
                <HeaderButton onClick={toggleStats}>
                    {statsExpanded
                        ? t.RacingCard.controlArea.viewStats
                        : t.RacingCard.controlArea.hideStats}
                </HeaderButton>

                {combinationsCount > 0 && (
                    <ClearAllBtn onClick={handleClearAllSelections}>
                        <img src={deleteSelectionsIcon} alt="clear" />
                    </ClearAllBtn>
                )}
            </div>
        </RaceCardHeaderContainer>
    );
};

export default RaceCardHeader;
