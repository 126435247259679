/**
 * Determines if one of the parameters exist in URL query
 * Optional validator argument is a function checking
 * the value of the URL parameters (will be called for each of them)
 * @param {string[]} parameters
 * @param {function.<boolean>} validator
 * @return {boolean}
 */
import { getLS } from 'common/storage/localStor';

export const WEB_ENV = process.env.WEB_ENV;

console.log('Environment: ' + WEB_ENV);

// const hasURLParameters = (parameters, validator = () => true) => {
//     const url = new URL(window.location.href);
//
//     for (let [key, value] of url.searchParams.entries()) {
//         if (parameters.includes(key) && validator(key, value)) {
//             return true;
//         }
//     }
//
//     return false;
// };

const KEY = 'REACT_APP_';
const MODULE_TOGGLING_KEY = 'MT_';
const FEATURE_TOGGLING_KEY = 'FT_';
const FEATURE_TOGGLING_PERMANENT_POSTFIX = '_P';
const FEATURE_TOGGLING_TEMP_POSTFIX = '_T';

/**
 * Returns environment variable set in the .env files.
 * Variable type will be considered.
 *
 * @param  {string}                  variable        Environment variable name
 *                                                   without REACT_APP_ prefix.
 * @param  {*}                       defaultValue
 * @return {string|number|boolean|undefined}
 */
const env = (variable, defaultValue) => {
    let value = process.env[KEY + variable];
    if (!value) {
        return defaultValue;
    }
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
};

const toggleModule = (moduleStable, moduleExperiment, envKey) => {
    return env(MODULE_TOGGLING_KEY + envKey) ? moduleExperiment : moduleStable;
};

const toggleFactory = (stableModuleFactory, experimentModuleFactory, envKey) => {
    return env(MODULE_TOGGLING_KEY + envKey) ? experimentModuleFactory() : stableModuleFactory();
};

const featureEnabled = (featureFlag, temp) => {
    return !!env(
        `${FEATURE_TOGGLING_KEY}${featureFlag}${
            temp ? FEATURE_TOGGLING_TEMP_POSTFIX : FEATURE_TOGGLING_PERMANENT_POSTFIX
        }`
    );
};

const byFlag = (featureFlag, target, temp = false, fallback = null) => {
    if (featureEnabled(featureFlag, temp)) {
        return target;
    } else {
        return fallback ? fallback() : undefined;
    }
};

const byEnv = (devCase, testCase, prodCase) => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return devCase;
        case 'test':
            return testCase;
        case 'production':
            return prodCase;
        default:
            return devCase;
    }
};

const isFeatureBranchBuild = () => {
    return /^der-\d+.dev.derby25.dk$/i.test(window.location.hostname);
};

const isDev = () =>
    ['dev.derby25.dk', 'devel.derby25.dk', 'localhost'].includes(window.location.hostname) ||
    isFeatureBranchBuild();

export const isLocalhost = () => window.location.hostname === 'localhost';

const byWebEnvFlag = (prodCase, devCase, stagingCase, featureFlag) => {
    const satisfiesQueryParam = !(featureFlag && getLS(featureFlag));

    if (isDev() && satisfiesQueryParam) {
        return devCase;
    } else if (window.location.hostname === 'test.derby25.dk' && satisfiesQueryParam) {
        return stagingCase || prodCase;
    } else {
        return prodCase;
    }
};

const byWebEnv = (prodCase, devCase, stagingCase, localCase) => {
    if (WEB_ENV === 'local') {
        return localCase || devCase;
    } else if (WEB_ENV === 'dev') {
        return devCase;
    } else if (WEB_ENV === 'staging') {
        return stagingCase || prodCase;
    } else {
        return prodCase;
    }
};

const getDevApiHost = () => {
    switch (window.location.host) {
        case 'bet25dev.dk':
            return 'https://api.bet25dev.dk';
        case 'bet25dev2.dk':
            return 'https://api.bet25dev2.dk';
        case 'bet25dev3.dk':
            return 'https://api.bet25dev3.dk';
        default:
            return 'https://api.bet25dev.dk';
    }
};

export {
    toggleModule,
    toggleFactory,
    byEnv,
    byFlag,
    isDev,
    byWebEnvFlag,
    byWebEnv,
    isFeatureBranchBuild,
    getDevApiHost,
};

export default env;
