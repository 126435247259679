import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import { performQuickPickBet as performQuickBet } from 'features/BetSlip2/state/actions';
import { getTranslatedErrorMessage } from 'utils/betting-service';

import useAuth from 'common/hooks/useAuth';
import { isUserLoggedIn, updateUserInfo } from 'common/actions/authActions';
import UserSessionAdapter from 'common/adapters/UserSession';
import NotificationConductor from 'common/conductors/NotificationConductor';

const DEFAULT_QUICK_PICK_AMOUNT = 50;

// @TODO: We should get rid of  withQuickPickBetting for Smart Lyn and use this hook instead
// Note: this hook already almost the copy of performQuickPickBet function of withQuickPickBetting hook
const usePerformQuickPickBet = ({ product, date, trackId, programNumber, onBetPerformed }) => {
    const [pending, setPending] = useState(false);
    const [quickPickAmount, setQuickPickAmount] = useState(DEFAULT_QUICK_PICK_AMOUNT);

    const auth = useAuth();
    const dispatch = useDispatch();

    const params = {
        raceDay: date,
        trackCode: trackId,
        programNumber,
        maxTotalBetAmount: quickPickAmount,
    };

    const performQuickPickBet = async () => {
        setPending(true);

        let postTime = moment(date).format('YYYY-MM-DD HH:mm');

        const bettingData = UserSessionAdapter.getPerformSaleData(
            params,
            auth.user.id,
            null,
            null,
            postTime
        );

        try {
            const res = await dispatch(performQuickBet(bettingData, product));

            if (res.success) {
                // first argument is skipped because below we call isUserLoggedIn anyway
                UserSessionAdapter.performUserUpdate(
                    () => {},
                    authData => dispatch(updateUserInfo(authData)),
                    res
                );

                onBetPerformed(res);

                dispatch(isUserLoggedIn());
            } else {
                setPending(false);

                const error = getTranslatedErrorMessage(res);
                NotificationConductor.error(error);
            }
        } catch (error) {
            setPending(false);

            NotificationConductor.error(error.message);
            console.error(error);
        }
    };

    return {
        quickPickBetPending: pending,
        performQuickPickBet,
        quickPickAmount,
        setQuickPickAmount,
    };
};

export default usePerformQuickPickBet;
