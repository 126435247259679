import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';

import NewTopBar from '../NewTopBar';
import Modal from 'ui/ModalContent';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';

import { byPlatform } from 'utils/platforms';
import { hidePopup } from 'utils/navigation';
import getTexts from 'utils/localization';
import useModals from 'common/hooks/useModals';
import { isFreeBetAvailable } from 'common/selectors/auth';

import {
    MobileContainer,
    Preview,
    PreviewImage,
    PreviewMainLine,
    PreviewText,
    PreviewTitle,
} from './styled';

import { BACKGROUNDS } from 'themes';

import notFoundAnimation from 'images/not-found-animation.json';

const t = getTexts();

const LottiePlayer = lazy(() => import('react-lottie-player/dist/LottiePlayerLight'));

const FreeBetBonusList = () => {
    const freeBetAvailable = useSelector(isFreeBetAvailable);

    if (!freeBetAvailable) {
        return (
            <>
                <Suspense fallback={null}>
                    <LottiePlayer
                        loop
                        animationData={notFoundAnimation}
                        play
                        style={{ width: '100px', height: '100px', margin: '0 auto' }}
                    />
                </Suspense>
                <Text align="center" size="x1.5" color="gray">
                    Ingen tilbud fundet
                </Text>
            </>
        );
    }

    return (
        <Preview>
            <PreviewImage />
            <PreviewText>
                <PreviewTitle>Tillykke! Du har optjent et freebet på XXX kr.</PreviewTitle>
                <PreviewMainLine>
                    Vidste du, at du også kan spille sportsbetting på Bet25? Prøv det nu med et XXX
                    kr. freebet.
                </PreviewMainLine>
            </PreviewText>
        </Preview>
    );
};

const FreeBet = () => {
    const { hide } = useModals();

    return byPlatform(
        <Modal background={BACKGROUNDS.neutral}>
            <NewTopBar
                goBack={() => {
                    hide('FREE_BET');
                }}
                close={() => {
                    hide('FREE_BET');
                    hidePopup('USER_AREA_MOBILE');
                }}
            >
                {t.userArea.nav.freeBet}
            </NewTopBar>
            <MobileContainer>
                <FreeBetBonusList />
            </MobileContainer>
        </Modal>,
        <Wrapper>
            <FreeBetBonusList />
        </Wrapper>
    );
};

export default FreeBet;
