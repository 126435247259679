import styled from 'styled-components';
import Button from 'ui/controls/Button';
import { byPlatform, isDesktop } from 'utils/platforms';
import { BACKGROUNDS, COLORS } from 'themes';
import { kombNrColDesktop } from '../Desktop/styled';
import { kombNrColMobile } from '../Mobile/styled';

export const PickButtonsContainerOld = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    @media (max-width: 640px) {
        align-items: center;
    }
`;

export const pickBtnGap = '5px';

export const PickButtonsContainer = styled.div<{ center?: boolean }>`
    display: flex;
    position: relative;
    height: 100%;
    flex-flow: row;
    justify-content: ${props => (props.center ? 'center' : byPlatform('center', 'flex-start'))};
    gap: ${pickBtnGap};
`;

export const KombPickColumnButtonsContainer = styled(PickButtonsContainer)`
    width: ${byPlatform(kombNrColMobile, kombNrColDesktop)};
    padding: ${byPlatform('5px 0', '0')};
`;

export const BetPickBtn = styled(Button)`
    transition: background 300ms ease;
    ${isDesktop && 'height: 30px'};
    ${isDesktop && 'line-height: 13px'};
    position: relative;
    opacity: ${({ disallowed }) => (disallowed ? 0.4 : 1)};
    cursor: ${({ scratched, active }) => (scratched && !active ? 'default' : 'pointer')};
    font-size: 16px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    width: ${({ betButtonStyles = {} }) =>
        (betButtonStyles.width ? betButtonStyles.width : byPlatform('38', '30', '45')) + 'px;'};
    height: ${({ betButtonStyles = {} }) =>
        (betButtonStyles.width ? betButtonStyles.width : byPlatform('38', '30', '45')) + 'px;'};
    border: ${({ active, reserved }) => (active || reserved ? 0 : '0.5px solid #C6CCDB')};
    color: ${({ active, reserved }) => (active || reserved ? COLORS.white : '#333333')};
    background: ${({ active, reserved, raceFinished }) => {
        if (active && reserved) {
            return `linear-gradient(45deg, #04a358 0%,#36b575 51%,#207cca 51%,#2989d8 51%,#0091d6 100%);`;
        }
        if (active) {
            return BACKGROUNDS.primary;
        }
        if (raceFinished) {
            return BACKGROUNDS.lightGrey;
        }
        if (reserved) {
            return BACKGROUNDS.blue;
        }

        return BACKGROUNDS.lightGrey;
    }};
    // when race has finished
    ${({ raceFinished }) => raceFinished && `color: ${COLORS.black}`};
    ${({ raceFinished }) => raceFinished && `border: 0`};
    ${({ raceFinished }) => raceFinished && `cursor: default`};
    ${({ raceFinished }) => raceFinished && `background: none`};

    ${isDesktop
        ? `&:hover
            background: ${({ active }: { active: boolean }) => !active && '#c6ccdb'};
        }`
        : ``}
`;

export const ReserveNumber = styled.span`
    display: block;
    background: ${BACKGROUNDS.accentAlt};
    height: 12px;
    width: 12px;
    line-height: 13px;
    border-radius: 10px;
    left: 4px;
    position: absolute;
    font-size: 8px;
    color: ${COLORS.black};
    transform: translate(21px, -28px);
`;
