import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import useAuth from 'common/hooks/useAuth';
import { Withdraw as WithdrawComponent } from '@it25syv/25syv-ui';
import getTexts from 'utils/localization';
import useModals from 'common/hooks/useModals';
import useActiveBonusQuery from './hook/useActiveBonusQuery';
import { forMobile } from 'utils/platforms';
import { Toolbar } from 'features/UserArea/Deposit/styled';
import Spinner from 'ui/Spinner';
import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { isUserLoggedIn } from 'common/actions/authActions';

const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-bottom: 16px;

    b {
        font-weight: 900;
    }
`;

const t = getTexts();

const NewWithdraw = () => {
    const dispatch = useDispatch();

    const auth = useAuth();
    const { activeBonus, isLoading } = useActiveBonusQuery();

    const { show, hide } = useModals();

    const [pending, setPending] = useState(false);

    const transferMoneyHandler = amount => {
        setPending(true);
        UserConductor.getPayout(); // todo do we still need this?
        UserConductor.withdraw(amount).then(res => {
            if (res.success) {
                dispatch(isUserLoggedIn());
                show('TRANSFER_MONEY_MODAL', 2, { amount });
            } else {
                NotificationConductor.error(res.errorMessage);
            }
            setPending(false);
        });
    };

    const removeBonus = bonusAmount => {
        show('REMOVE_BONUS_CONFIRM', 2, { bonusAmount });
    };

    const selfClose = () => {
        hide('WITHDRAW');
    };

    return (
        <>
            {forMobile(
                <Toolbar title={t.userArea.withdraw.title} goBack={selfClose} close={selfClose} />
            )}
            <StyledWrapper>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <WithdrawComponent
                        style={{ height: '100%' }}
                        product={'heste'}
                        userData={auth.user}
                        bonusesData={activeBonus || []}
                        onRemove={removeBonus}
                        transferMoneyHandler={transferMoneyHandler}
                        redirectUrl={() => (window.location.pathname = '/casino')}
                        payoutPending={pending}
                    />
                )}
            </StyledWrapper>
        </>
    );
};

export default NewWithdraw;
