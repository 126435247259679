import React from 'react';
import styled from 'styled-components';
import {
    GameHeaderCol,
    GameHeaderColCenter,
    GameHeaderColIcon,
    GameHeaderColIconCenter,
} from './styled';
import { PRODUCT_IDS } from 'configs/products';
import { buttonsColWidth, buttonsColWidthDefault } from 'features/Game';
import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';
import { isTerminal, forTerminal } from 'utils/platforms';

import { SORTING_TYPE_ASC, SORTING_TYPE_DESC, SORTING_TYPE_DISABLED } from 'ui/Sortable';
import Fa from 'ui/Fa';

import getTexts from 'utils/localization';
import { TableSubHeader as TableSubHeaderDef } from 'ui/Table';

const t = getTexts();

const buttonsColTitleMap = {
    [PRODUCT_IDS.T]: t.RacingCard.subHeader.trio,
    [PRODUCT_IDS.TV]: t.RacingCard.subHeader.twin,
    [PRODUCT_IDS.K]: t.RacingCard.subHeader.komb,
};

export const excludedProducts = ['DD', 'LD'];

const SortIcon = ({ icon }) => <Fa svgName={icon} color={'white'} size={'s'} section={'solid'} />;

const TableSubHeader = styled(TableSubHeaderDef)`
    background: ${byPlatform(BACKGROUNDS.white, '#333')};
    color: ${byPlatform(COLORS.black, COLORS.white)};
    border-bottom: ${byPlatform('1px solid #EBEFF6', 'none')};
`;

const getIconCode = (column, sortingStatuses) => {
    const map = {
        [SORTING_TYPE_DISABLED]: 'sort',
        [SORTING_TYPE_ASC]: 'sort-up',
        [SORTING_TYPE_DESC]: 'sort-down',
    };
    const columnSortingStatus = sortingStatuses[column];

    return map[columnSortingStatus] || 'sort';
};

const getOddsHeader = product => {
    switch (product.id) {
        case PRODUCT_IDS.P:
            return 'P-Odds';
        case PRODUCT_IDS.VP:
            return 'V-Odds/P-Odds';
        default:
            return 'V-Odds';
    }
};

const GameHeaderMobile = props => {
    const { product, race, toggleSortingHandler, sortingStatuses } = props;
    const isVProduct = product.isVProduct();
    const toggleSortingByNumber = () => toggleSortingHandler('startNr');
    const toggleSortingByVPercentage = () => toggleSortingHandler('VPercentage');

    const hideButtonsColumn = () =>
        !race.saleOpen && (product.isTrio() || product.isTvilling() || product.isKomb());

    return (
        <TableSubHeader style={{ margin: 0, padding: '5px 10px' }}>
            {!hideButtonsColumn() && (
                <GameHeaderCol
                    onClick={toggleSortingByNumber}
                    basis={buttonsColWidth[product.id] ?? buttonsColWidthDefault}
                    textCenterMobile
                >
                    {buttonsColTitleMap[product.id] || t.RacingCard.subHeader.nr}
                </GameHeaderCol>
            )}
            <GameHeaderCol grow={0.8}>{t.RacingCard.subHeader.horseJockey}</GameHeaderCol>
            {isVProduct && !excludedProducts.includes(product.id) && (
                <GameHeaderColIcon
                    onClick={toggleSortingByVPercentage}
                    pl={0}
                    pr={0}
                    grow={0.15}
                    style={{ justifyContent: 'center' }}
                >
                    <Fa
                        color="white"
                        size="s"
                        section="solid"
                        svgName={getIconCode('VPercentage', sortingStatuses)}
                        style={{
                            lineHeight: '10px',
                            paddingRight: '2px',
                        }}
                    />
                    {product.id}%
                </GameHeaderColIcon>
            )}
            <GameHeaderColCenter grow={0.3}>{getOddsHeader(product)}</GameHeaderColCenter>
            <GameHeaderColCenter grow={0.3}>{t.RacingCard.subHeader.stats}</GameHeaderColCenter>
        </TableSubHeader>
    );
};

const GameHeaderDesktop = props => {
    const { product, race, gallop, toggleSortingHandler, sortingStatuses } = props;
    const isVProduct = typeof product.isVProduct === 'function' && product.isVProduct();
    const toggleSortingByNumber = () => toggleSortingHandler('startNr');
    const toggleSortingByVPercentage = () => toggleSortingHandler('VPercentage');
    const hideButtonsColumn = () =>
        !race.saleOpen && (product.isTrio() || product.isTvilling() || product.isKomb());

    const padding = byPlatform('0 10px 0 20px', '2px 10px 2px 20px', '3px 10px 3px 20px');

    return (
        <TableSubHeader style={{ margin: 0, padding }}>
            {!hideButtonsColumn() && (
                <GameHeaderCol
                    onClick={toggleSortingByNumber}
                    basis={buttonsColWidth[product.id] ?? buttonsColWidthDefault}
                    style={byPlatform({}, { cursor: 'pointer' }, { textAlign: 'center' })}
                    productId={product.id}
                >
                    <SortIcon icon={getIconCode('startNr', sortingStatuses)} />
                    {buttonsColTitleMap[product.id] || t.RacingCard.subHeader.nr}
                </GameHeaderCol>
            )}
            <GameHeaderCol grow={0.8}>
                {isTerminal ? t.RacingCard.subHeader.horse : t.RacingCard.subHeader.horseJockey}
            </GameHeaderCol>
            {forTerminal(<GameHeaderCol grow={0.8}>{t.RacingCard.subHeader.jockey}</GameHeaderCol>)}
            {isVProduct && !excludedProducts.includes(product.id) && (
                <GameHeaderColIconCenter
                    onClick={toggleSortingByVPercentage}
                    style={{ cursor: 'pointer', justifyContent: 'center' }}
                    grow={0.15}
                >
                    <SortIcon icon={getIconCode('VPercentage', sortingStatuses)} />
                    {product.id}%
                </GameHeaderColIconCenter>
            )}
            <GameHeaderColCenter grow={0.3}>{getOddsHeader(product)}</GameHeaderColCenter>
            <GameHeaderCol grow={0.5}>{t.ShortStatistic.coach}</GameHeaderCol>
            {gallop && <GameHeaderCol grow={0.1}>Formtal</GameHeaderCol>}
            <GameHeaderColCenter grow={0.1}>
                {gallop ? t.ShortStatistic.weight : t.ShortStatistic.shoe}
            </GameHeaderColCenter>
            <GameHeaderCol grow={0.3}>{t.ShortStatistic.money}</GameHeaderCol>
            <GameHeaderCol grow={0.3}>{t.ShortStatistic.start}</GameHeaderCol>
            <GameHeaderColCenter grow={0.4}>{t.ShortStatistic.starter}</GameHeaderColCenter>
        </TableSubHeader>
    );
};

export default byPlatform(GameHeaderMobile, GameHeaderDesktop);
