import React from 'react';
import { TransferMoneyModal as TransferMoney } from '@it25syv/25syv-ui';
import useModals from 'common/hooks/useModals';
import { TransparentDesktopModalLayer } from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';

const TransferMoneyModal = ({ modalData }) => {
    const { hide } = useModals();
    const { amount } = modalData;
    return (
        <TransparentDesktopModalLayer>
            <FlexWrapper padding="0" alignItems={'center'}>
                <TransferMoney amount={amount} closeModal={() => hide('TRANSFER_MONEY_MODAL')} />
            </FlexWrapper>
        </TransparentDesktopModalLayer>
    );
};

export default TransferMoneyModal;
