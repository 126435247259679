import React from 'react';
import PostPositionDelimiter from '../Desktop/PostPositionDelimiter';

import { AnyRace, Horse, VHorse } from 'features/TrackPage/model/Race';

import useToggleHorseSelection from 'features/TrackPage/hooks/useToggleHorseSelection';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import { ShoeInfoRace } from 'features/TrackPage/server/trackBetInfo';

import PickButtons from '../common/PickButtons';
import HorseName from '../common/HorseName';
import ShortStatistic from './ShortStatistic';
import { extractShoeInfo } from '../Desktop/WeightOrShoeCol';

import { StatsCol, StatsIcon, TableCol, TableRow } from './styled';
import { shouldHideNrCol, shouldShowStartNrWithName } from '../utils';

import { trackEvent, trackingIDs } from 'utils/tracking';
import useModals from 'common/hooks/useModals';
import { PRODUCT_IDS } from 'configs/products';

import statsIcon from 'images/icons/stats.svg';

const { T, K } = PRODUCT_IDS;

interface HorseRowProps {
    horse: Horse;
    index: number;
    race: AnyRace;
    raceShoeInfo: ShoeInfoRace;
    statsExpanded: boolean;
}

const HorseRow = ({ horse, index, race, raceShoeInfo, statsExpanded }: HorseRowProps) => {
    const { isVStrictProduct, productId, isGallop, isTrot } = useTrackPage();

    const { toggleSelection } = useToggleHorseSelection({ horse, race });

    const { show: showModal } = useModals();

    const scratched = horse.vpPool.scratched;
    const allowed = !scratched;
    const VPercentage = (horse as VHorse).VPool?.stakeDistributionPercent;
    const shoeInfoStart = raceShoeInfo.shoeInfoStart.shoeInfoStart[index];
    const shoeInfo = extractShoeInfo(shoeInfoStart);

    const isTrio = productId === T;
    const isKomb = productId === K;

    const showStartNumber = shouldShowStartNrWithName(race, productId);
    const hideNrCol = shouldHideNrCol(race, productId);

    const showStatsPopup = (e: Event, horse: Horse) => {
        e.stopPropagation();

        trackEvent({
            event: trackingIDs.racingCard.openStatsMobile,
            category: 'shortcuts',
            action: horse.horse.horseNameAndNationality,
        });

        showModal('GAME_STATISTIC', 1, { race, start: horse });
    };

    const handleRowClick = () => {
        if (isTrio || isKomb) {
            return;
        }

        toggleSelection();
    };

    return (
        <>
            <PostPositionDelimiter
                isTrot={isTrot}
                start={horse}
                starts={race.horses}
                productId={productId}
            />
            <TableRow
                hideNrCol={hideNrCol}
                isVStrictProduct={isVStrictProduct}
                productId={productId}
                data-test-id={horse.vpPool.scratched ? 'scratched' : 'outright'}
                onClick={handleRowClick}
            >
                <TableCol align={'center'} bold>
                    <PickButtons horse={horse} race={race} />
                </TableCol>
                <TableCol capitalize bold short strike={scratched}>
                    <HorseName horse={horse} showStartNumber={showStartNumber} />
                </TableCol>
                {isVStrictProduct && (
                    <TableCol bold align={'center'}>
                        {allowed && VPercentage ? VPercentage + '%' : '-'}
                    </TableCol>
                )}
                <TableCol bold align={'center'}>
                    {allowed ? horse.vpPool.vinnarOdds.odds : '-'}
                </TableCol>
                <StatsCol onClick={(e: Event) => showStatsPopup(e, horse)}>
                    <StatsIcon src={statsIcon} />
                </StatsCol>
            </TableRow>

            {statsExpanded && (
                <ShortStatistic start={horse} gallop={isGallop} shoeInfo={shoeInfo} />
            )}
        </>
    );
};

export default HorseRow;
