import React, { Component } from 'react';
import styled from 'styled-components';
import BetButton from './BetButton';
import Checkbox from 'ui/Checkbox';
import { byPlatform, forTerminal } from 'utils/platforms';

const ButtonsWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    position: relative;
    height: 100%;
`;

const TrioBetButton = styled(BetButton)`
    margin-right: ${byPlatform('4px', '10px')};
`;

class TrioBetButtons extends Component {
    constructor() {
        super();

        this.state = {
            rowSelected: false,
        };
    }

    onActivateFirstPlace = () => {
        const { onActivate } = this.props;
        onActivate && onActivate(1);
    };

    onActivateSecondPlace = () => {
        const { onActivate } = this.props;
        onActivate && onActivate(2);
    };

    onActivateThirdPlace = () => {
        const { onActivate } = this.props;
        onActivate && onActivate(3);
    };

    onDisactivateFirstPlace = () => {
        const { onDisactivate } = this.props;
        onDisactivate && onDisactivate(1);
    };

    onDisactivateSecondPlace = () => {
        const { onDisactivate } = this.props;
        onDisactivate && onDisactivate(2);
    };

    onDisactivateThirdPlace = () => {
        const { onDisactivate } = this.props;
        onDisactivate && onDisactivate(3);
    };

    toggleRowSelection = event => {
        event && event.stopPropagation();

        if (this.props.scratched) {
            return;
        }

        this.setState(
            {
                rowSelected: !this.state.rowSelected,
            },
            () => {
                const { onActivate, onDisactivate } = this.props;
                const handler = this.state.rowSelected ? onActivate : onDisactivate;

                handler(1);
                handler(2);
                handler(3);
            }
        );
    };

    render() {
        return (
            <ButtonsWrapper>
                {forTerminal(
                    <Checkbox
                        checked={this.state.rowSelected}
                        size="x2"
                        styles={{
                            height: '15px',
                            margin: '10px',
                        }}
                        changeHandler={this.toggleRowSelection}
                    />
                )}
                <TrioBetButton
                    {...this.props}
                    onActivate={this.onActivateFirstPlace}
                    betValue={1}
                    onDisactivate={this.onDisactivateFirstPlace}
                />
                <TrioBetButton
                    {...this.props}
                    onActivate={this.onActivateSecondPlace}
                    betValue={2}
                    onDisactivate={this.onDisactivateSecondPlace}
                />
                <TrioBetButton
                    {...this.props}
                    onActivate={this.onActivateThirdPlace}
                    onDisactivate={this.onDisactivateThirdPlace}
                    betValue={3}
                />
            </ButtonsWrapper>
        );
    }
}

export default TrioBetButtons;
