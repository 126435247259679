import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import previewBg from 'images/sport-middle-finger.png';

export const MobileContainer = styled.div`
    background: ${BACKGROUNDS.neutral};
    padding: 16px;
`;

export const Preview = styled.div`
    border: 1px solid ${COLORS.borders.neutral};
    border-radius: 8px;
    cursor: pointer;
`;

export const PreviewImage = styled.div`
    display: flex;
    height: 150px;
    background: url(${previewBg}) repeat-x;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
`;

export const PreviewText = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: ${COLORS.black};
`;

export const PreviewTitle = styled.h3`
    font-family: 'ATG Text';
    font-size: 18px;
    line-height: 24px;
    margin: 0;
`;

export const PreviewMainLine = styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
`;
