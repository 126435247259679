import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from 'common/hooks/useAuth';
import { byPlatform } from 'utils/platforms';

import { fadeInMixin } from './animations/FadeIn2';
import Icon from 'ui/Icon';
import closeBtnIcon from 'images/icons/close-thin.svg';
import { setLoginTimeVisibility } from '../common/actions/uiActions';

const Popup = styled.div`
    border: 1px solid #e4e9ef;
    background: #f5f8fc;
    color: #1e1e1e;
    font-size: ${byPlatform('10px', '13px')};
    width: ${byPlatform('calc(100% - 40px)', '441px')};
    box-sizing: border-box;
    padding: ${byPlatform('8px 20px', '12px 8px 12px 32px')};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: fixed;
    bottom: ${byPlatform('unset', '15px')};
    top: ${byPlatform('40px', 'unset')};
    left: 50%;
    transform: translateX(-50%);
    z-index: ${999};
    border-radius: 4px;
    & span.login-time {
        font-weight: 900;
    }

    & img {
        width: 24px;
        height: 24px;
        margin-left: 30px;
        cursor: pointer;
    }
    ${fadeInMixin};
`;

const LoginTimePopup = () => {
    const auth = useAuth();

    const showLoginTimePopup = useSelector(state => state.ui.showLoginTimePopup);

    const dispatch = useDispatch();

    useEffect(() => {
        if (showLoginTimePopup) {
            window.setTimeout(() => dispatch(setLoginTimeVisibility(false)), 5000);
        }
    }, [showLoginTimePopup]);

    if (!showLoginTimePopup || !auth.user) {
        return null;
    }

    const formattedDate = moment
        .utc(auth.user.lastLoginUTC)
        .local()
        .format('DD/MM-YYYY [kl.] HH:mm:ss');

    return (
        <Popup animationName="loginTimeFadeIn">
            <div>
                Velkommen tilbage. Du var sidst logget ind{' '}
                <span className="login-time">{formattedDate}</span>
            </div>
            {byPlatform(
                null,
                <Icon
                    onClick={() => dispatch(setLoginTimeVisibility(false))}
                    src={closeBtnIcon}
                    color="black"
                />
            )}
        </Popup>
    );
};

export default LoginTimePopup;
