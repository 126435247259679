import React, { useEffect } from 'react';

import TrackBar from 'features/EventBoard/components/TrackBar';
import VRaceOverview from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview';

import useURLParameters from './hooks/useURLParameters';

import RacesRow from './components/RaceLine/RacesRow';
import { PageContainer, Navigation } from './components/styled';

import { byPlatform, forMobile, forDesktop } from 'utils/platforms';
import useTrackPage from './hooks/useTrackPage';
import ProductsRow from './components/Products/ProductsRow';
import RaceInfo from './components/RaceCard/Mobile/RaceInfo';
import RaceCardSlider from './components/RaceCard/Mobile';
import RaceCardDesktop from './components/RaceCard/Desktop';
import ProductTipsRow from './components/ProductTips/ProductTipsRow';
import BetslipButtonRow from './components/RaceCard/Mobile/BetslipButtonRow';
import RaceCardReplays from './components/RaceCardReplays';
import { useVRaceOverview } from './components/RaceLine/OverviewCheckbox';
import PoolOverview from './components/PoolOverview';

import InlineError from 'ui/InlineError';
import OverviewQuickPick from 'features/QuickPick/OverviewQuickPick';
import useFillBetslipFromURL from './hooks/useFillBetslipFromURL';
import useAdjustATGLinks from './hooks/useAdjustATGLinks';
import SpilTips from '../SpilTips';
import TracksRow from './components/TrackLine/TracksRow';
import Forecast from './components/RaceCard/Desktop/Forecast';
import SpilklubGroupsSection from '../Spilklub2/components/FocusGroups';
import PdfSection from 'features/TrackPage/components/PdfDownload/Mobile';

const TrackPage = () => {
    const { syncURLParameters } = useURLParameters();

    const {
        raceDay,
        calendarFetched,
        race,
        races,
        racesLoading,
        calendarError,
        isVStrictProduct,
        product,
        trackId,
        isVProduct,
    } = useTrackPage();

    useEffect(() => {
        syncURLParameters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useAdjustATGLinks();

    useFillBetslipFromURL();

    //console.log(races);

    const hideNavigation = calendarFetched && raceDay.trackId === 0;

    const { overviewEnabled } = useVRaceOverview();

    const showVRaceOverview = isVStrictProduct && !racesLoading && overviewEnabled;

    const showTips = ['DK', 'SE', 'NO'].includes(raceDay.country.code);

    if (calendarError) {
        return <InlineError>Could not display the race calendar.</InlineError>;
    }

    return (
        <div data-test-id="home-page-betting-root">
            <TrackBar />

            <PageContainer>
                {!hideNavigation && (
                    <Navigation>
                        <TracksRow />
                        <ProductsRow />
                        <RacesRow />
                    </Navigation>
                )}

                <ProductTipsRow />
                <RaceCardReplays />

                <Forecast />

                {forMobile(<RaceInfo race={race} />)}

                {showVRaceOverview && race.saleOpen && forDesktop(<VRaceOverview />)}

                {byPlatform(<RaceCardSlider />, <RaceCardDesktop />)}

                {showTips && (
                    <SpilTips
                        date={raceDay.raceDayDate}
                        race={race}
                        selectedProduct={product}
                        trackId={trackId}
                        isVProduct={isVProduct}
                        raceDay={raceDay}
                    />
                )}

                <PoolOverview race={race} />

                {byPlatform(<PdfSection />, null)}

                <SpilklubGroupsSection isTrackMode />

                <OverviewQuickPick size={byPlatform(2, 3)} isTrackQuickPick padding={'10px 0'} />

                {forMobile(<BetslipButtonRow />)}
            </PageContainer>
        </div>
    );
};

export default TrackPage;
