import React, { Component } from 'react';
import Spinner from 'ui/Spinner';
import { byPlatform } from 'utils/platforms';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'utils/platforms';

import getTexts from 'utils/localization';
import { PaymentHeader } from '../Withdraw/styled';
import { connect } from 'react-redux';
import { openLogin } from 'utils/navigation';
const t = getTexts();

export const AMOUNT_REG_EX = new RegExp(' ' + t.currency, 'g');

class Deposit extends Component {
    constructor() {
        super();

        this.iframeRef = React.createRef();

        this.state = {
            loaded: false,
            paymentPage: false,
        };

        window.hideCloseButton = () =>
            this.props.disableCloseButton && this.props.disableCloseButton();
        window.closeModal = () => this.props.hideModal('DEPOSIT');
    }

    componentDidUpdate() {
        if (!this.props.user && this.props.authFinished) {
            this.props.showLoginModal();
        }
    }

    urlChangedHandler = () => {
        this.setState({
            loaded: true,
        });

        // try {
        //     if (
        //         event.target.contentWindow.location.href.indexOf('/dibs') > -1
        //     ) {
        //         this.setState({
        //             paymentPage: true,
        //         });
        //         window.location.href = event.target.contentWindow.location.href;
        //     }
        // } catch (error) {
        //     console.warn(error);
        // }
    };

    render() {
        return (
            <div style={{ position: 'relative' }}>
                {!this.state.loaded && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            right: '0px',
                            bottom: '0px',
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                            display: 'flex',
                            justifyContent: 'center',
                            flexFlow: 'column',
                            textAlign: 'center',
                        }}
                    >
                        <Spinner />
                    </div>
                )}

                {this.state.paymentPage && isMobile ? (
                    <PaymentHeader>Indbetaling</PaymentHeader>
                ) : null}

                <iframe
                    ref={this.iframeRef}
                    title="Dibs payment form"
                    src={'/heste/deposit-modal'}
                    width="100%"
                    height={byPlatform('100%', '610px')}
                    onLoad={this.urlChangedHandler}
                    id="paymentFrame"
                    style={{
                        border: '0px',
                        margin: '0 auto',
                        height: byPlatform('-webkit-fill-available', undefined),
                        minHeight: byPlatform(window.innerHeight - 20, undefined),
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authFinished: state.auth.finished,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showLoginModal: () => {
            dispatch(openLogin({ replace: true }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Deposit));
