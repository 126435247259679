import { useQuery } from '@tanstack/react-query';
import UserConductor from 'common/conductors/UserConductor';

const fetchActiveBonus = async () => {
    const activeBonus = await UserConductor.getActiveBonusList();
    return activeBonus.data;
};

const useActiveBonusQuery = () => {
    const { status, data, error, isSuccess, isLoading, refetch } = useQuery<unknown, Error>({
        queryKey: ['activeBonus'],
        queryFn: fetchActiveBonus,
        refetchOnWindowFocus: false,
        enabled: true,
    });

    return { status, activeBonus: data ?? [], error, isSuccess, isLoading, refetch };
};

export default useActiveBonusQuery;
